import { Close, Mail, Telegram } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import AccountContext from "src/contexts/AccountContext";
import LayoutContext from "src/contexts/LayoutContext";
import useAuth from "src/hooks/useAuth";
import Logo from "../Logo";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 550,
      border: "1px rgba(255, 255, 255, 0.2) solid",
    },
    chip: {
      width: 18,
      height: 18,
      backgroundColor: "rgba(145, 158, 171, 0.24)",
      borderRadius: 0.51,
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  })
);

function AlertWellcomeModal() {
  const { t } = useTranslation("dashboard");
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { layout, setModalAlertWellcomeModal } = useContext(LayoutContext);
  const { switchAccount } = useAuth();
  const { accounts, mutate } = useContext(AccountContext);

  const handleCloseModalWellcome = () => {
    window.localStorage.setItem("firstAccess", "concluded");
    setModalAlertWellcomeModal(false);
  };

  const navigateToAccountDemo = async () => {
    const accountId = window.localStorage.getItem("accountId");

    const accountActive = accounts.find((c: any) => c.id === accountId);

    if (window.location.pathname === "/traderoom") {
      if (accountActive) {
        if (accountActive?.label === "DEMO") {
          handleCloseModalWellcome();
        } else {
          const accountsDemo = accounts
            .filter((item: any) => item.environment !== "GLOBAL")
            .find((a: any) => a.label === "DEMO");

          await switchAccount(accountsDemo);
          mutate("/users/listAccounts");
          setModalAlertWellcomeModal(false);
          window.location.reload();
        }
      }
    }
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={layout.modalAlertWellcomeModal}
      onClose={() => handleCloseModalWellcome()}
    >
      <Paper
        sx={{ p: 3, minWidth: isMobile ? "80%" : null }}
        className={classes.paper}
      >
        <IconButton
          aria-label="close"
          onClick={() => handleCloseModalWellcome()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <Stack direction="row" spacing={2}>
          <Stack direction={"column"} spacing={2}>
            <Typography fontSize={16} fontWeight="bold" textAlign={"center"}>
              Seja bem-vindo à ebinex!
            </Typography>
            <Typography fontSize={14} fontWeight={300} textAlign={"center"}>
              Estamos extremamente felizes em saber que você se juntou à
              revolução do mercado de trade. Estamos à disposição para ajudá-lo
              a ter a melhor experiência possível em nossa plataforma. Não
              hesite em entrar em contato com nosso suporte sempre que precisar.
            </Typography>
            <Typography fontSize={14} fontWeight={300} textAlign={"center"}>
              Para testar a conta demo, clique no botão verde abaixo.
            </Typography>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={(e: any) => navigateToAccountDemo()}
              fullWidth
            >
              Ir para conta demonstrativa
            </Button>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              spacing={2}
              pt={3}
            >
              <Logo
                sx={{
                  mt: 0.5,
                  height: 18,
                  width: 80,
                }}
              />
              <Stack direction="row" spacing={1}>
                <IconButton
                  color="primary"
                  aria-label="telegram"
                  href="https://t.me/ebinexofficial"
                  target="_blank"
                >
                  <Telegram fontSize="small" />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="email"
                  href="mailto:suporte@ebinex.com"
                >
                  <Mail fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}

export default AlertWellcomeModal;
