import { ArrowBack } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";

import WalletIcon from "@mui/icons-material/Wallet";
import { makeStyles, styled, useTheme } from "@mui/styles";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import mobileLogoImage from "src/assets/images/x.svg";
import AccountContext from "src/contexts/AccountContext";
import LayoutContext from "src/contexts/LayoutContext";
import useAuth from "src/hooks/useAuth";
import { notifyError } from "src/utils/toast";
import Logo from "./Logo";
import AccountMenu from "./dashboard/AccountMenu";
import LanguagePopover from "./dashboard/LanguagePopover";
import SoundButton from "./dashboard/overview/SoundButton";
import MenuSelectAccount from "./menus/MenuSelectAccount";
import SelectAccountSkeleton from "./skeleton/SelectAccountSkeleton";
import SubHeaderDense from "./trade/SubHeaderDense";

const useStyles = makeStyles({
  navDisplayFlex: {
    display: "flex",
  },
  linkText: {
    textDecoration: "none",
    color: "white",
    fontSize: "0.875rem",
  },
  buttonLink: {},
});

const MobileLogo = styled("img")({
  "@media (max-width: 400px)": {
    display: "none",
  },
  height: 26,
  marginRight: 8,
});

const navLinks = [
  { title: "Trade", path: "/traderoom" },
  { title: "Como operar", path: "" },
  { title: "Quem somos", path: "" },
  { title: "Contato", path: "" },
];

const HeaderDashboard: FC = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["dashboard", "home"]);
  const auth = useAuth();
  const { accounts, activeAccount } = useContext(AccountContext);
  const location = useLocation();
  const { layout, setActiveDrawer, setActiveTab, setModalBetaDepositInfo } =
    useContext(LayoutContext);
  const classes = useStyles();
  const [locale, setLocale] = useState("Português");
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenDrawe = (tab: string) => {
    setActiveDrawer("auth", tab);
  };

  const navigateDashboard = () => {
    if (!auth.isAuthenticated) {
      setActiveDrawer("auth", "login");
    } else {
      navigate("/traderoom");
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      await auth.logout();
      navigate("/");
    } catch (err) {
      console.error(err);
      notifyError("Unable to logout");
    }
  };

  return (
    <AppBar
      elevation={1}
      sx={{
        backgroundColor: "background.paper",
        borderBottom: "solid 3px #000",
        color: "text.secondary",
        flexGrow: 1,
        px: 1,
      }}
    >
      <Toolbar
        variant="dense"
        disableGutters={true}
        sx={{ minHeight: isMobile ? 60 : 70 }}
      >
        {isMobile && (
          <RouterLink
            to={`${auth.isAuthenticated ? "/traderoom" : "/"}`}
            onClick={() => setActiveTab("dashboard")}
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
            }}
          >
            <MobileLogo src={mobileLogoImage} alt="" />
          </RouterLink>
        )}
        <Stack
          direction="row"
          justifyContent={isMobile ? "flex-start" : "space-between"}
          alignSelf="center"
          spacing={isMobile ? 0 : 2}
          sx={{
            width: "100%",
            ml: { xs: 1, lg: "5px" },
            mr: { lg: "5px" },
          }}
        >
          <>
            {location.pathname !== "/traderoom" ? (
              <Button
                sx={{ mr: "auto", color: "#fff" }}
                onClick={() => {
                  window.location.href = "/traderoom";
                }}
                color="inherit"
                startIcon={<ArrowBack />}
              >
                <Typography>Traderoom</Typography>
              </Button>
            ) : (
              <SubHeaderDense />
            )}
          </>

          {!isMobile && (
            <RouterLink
              to={`${auth.isAuthenticated ? "/traderoom" : "/"}`}
              onClick={() => setActiveTab("dashboard")}
              style={{
                margin: 0,
                position: "fixed",
                left: "47%",
                top: "50%",
              }}
            >
              <Logo
                sx={{
                  mt: 0.5,
                  height: 25,
                  width: 104,
                  position: "fixed",
                  top: 22,
                }}
              />
            </RouterLink>
          )}

          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"center"}
            alignItems="center"
            alignSelf="center"
            sx={{
              ml: isMobile ? 1 : 0,
              width: isMobile ? "100%" : undefined,
            }}
          >
            {location.pathname !== "/traderoom" ? (
              <SoundButton />
            ) : (
              !isMobile && <SoundButton />
            )}

            {(!isMobile || location.pathname !== "/traderoom") && (
              <Box sx={{ ml: "auto" }}>
                <LanguagePopover />
              </Box>
            )}
            <Box>
              {accounts.length > 0 && activeAccount ? (
                <MenuSelectAccount />
              ) : (
                <SelectAccountSkeleton />
              )}
            </Box>
            {(!isMobile || location.pathname === "/traderoom") && (
              <Button
                color="primary"
                component={RouterLink}
                size="large"
                variant="contained"
                to="/dashboard/profile?tab=deposit"
                sx={{
                  width: isMobile ? "100%" : undefined,
                  minWidth: isMobile ? 42 : undefined,
                  height: isMobile ? 42 : undefined,
                  px: isMobile ? 0 : undefined,
                  py: isMobile ? 0 : undefined,
                }}
              >
                {!isMobile && t("deposit")}
                {isMobile && <WalletIcon />}
              </Button>
            )}

            <AccountMenu openTemporaryModalFunction={handleOpen} />
            {/* {isMobile && (
                <IconButton onClick={handleLogout}>
                  <Logout sx={{ color: "#FF5382" }} fontSize="small" />
                </IconButton>
              )} */}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderDashboard;
