import { Skeleton, Typography } from "@mui/material";
import { useContext } from "react";

import CountdonwContext from "src/contexts/CountdownContext";

function Countdown() {
  const { count, timeFormat } = useContext(CountdonwContext);

  if (timeFormat === "") {
    return <Skeleton variant="text" sx={{ ml: 0.4 }} width={80} height={15} />;
  }

  return (
    <Typography
      fontWeight={600}
      color={count < 6 ? "#FF5382" : "#00A667"}
      sx={{ fontWeight: "bold", ml: 0.4 }}
      fontSize={10}
    >
      {timeFormat} segundos
    </Typography>
  );
}

export default Countdown;
