import { useMediaQuery, useTheme } from "@mui/material";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { apiPost } from "src/services/apiService";
import useUser from "src/swr/use-user";
import { RequestPix } from "src/types/deposit";
import formatCurrency from "src/utils/formatCurrency";
import { notifyError, notifySuccess } from "src/utils/toast";
import { useLocalStorage } from "usehooks-ts";
import { Candle, Ticker, VolumeBook } from "../types/candle";
import { Order } from "../types/order";
import LayoutContext from "./LayoutContext";
import TradingViewContext from "./TradingViewContext";

import orderLoseSound from "src/assets/audio/notifications/bottle-205353.mp3";
import orderWinSound from "src/assets/audio/notifications/new-notification-7-210334.mp3";
import useQuery from "src/hooks/useQuery";
import useSound from "use-sound";
import SettingsContext from "./SettingsContext";

interface State {
  selectedCandle: string | "M1"; // Default timeframe
  orderValue: number;
  candles: Candle[];
  userBalance: number;
  userLiveOperations: { [key: string]: Order };
  userOrders: Order[];
  chartOrders: any[];
  volumeBook: VolumeBook;
  tickerBook: { candleTimeFrame: string; ticker: Ticker }[];
  serverTime: number;
}

interface ApiProviderProps {
  children: ReactNode;
}

type ChangeTimeframeAction = {
  type: "CHANGE_TIMEFRAME";
  payload: {
    selectedCandle: string;
  };
};

type UpdateCandlesAction = {
  type: "UPDATE_CANDLES";
  payload: {
    candles: Candle[];
  };
};

type UpdateUserBalanceAction = {
  type: "UPDATE_USER_BALANCE";
  payload: {
    userBalance: number;
  };
};

type UpdateLiveOperationsAction = {
  type: "UPDATE_USER_LIVE_OPERATIONS";
  payload: {
    userLiveOperations: { [key: string]: Order };
  };
};

type UpdateUserOrdersAction = {
  type: "UPDATE_USER_ORDERS";
  payload: {
    userOrders: Order[];
  };
};

type UpdateChartOrdersAction = {
  type: "UPDATE_CHART_ORDERS";
  payload: {
    chartOrders: any[];
  };
};

type UpdateTickerBookAction = {
  type: "UPDATE_TICKERBOOK_ACTION";
  payload: {
    candleTimeFrame: string;
    ticker: Ticker;
  };
};

type UpdateServerTimeAction = {
  type: "UPDATE_SERVER_TIME";
  payload: {
    serverTime: number;
  };
};

type UpdateUserBookAction = {
  type: "UPDATE_USER_BOOK";
};

type SetOrderValueAction = {
  type: "SET_ORDER_VALUE";
  payload: {
    orderValue: number;
  };
};

const initialState: State = {
  selectedCandle: "M1",
  orderValue: 1,
  candles: [],
  userBalance: 0,
  userLiveOperations: {},
  userOrders: [],
  chartOrders: [],
  volumeBook: null,
  tickerBook: null,
  serverTime: null,
  // tickerBook: [],
  // ticker: []
};

type Action =
  | ChangeTimeframeAction
  | UpdateCandlesAction
  | UpdateUserBalanceAction
  | UpdateTickerBookAction
  | UpdateUserBookAction
  | UpdateServerTimeAction
  | UpdateLiveOperationsAction
  | UpdateUserOrdersAction
  | UpdateChartOrdersAction
  | SetOrderValueAction;

const handlers: Record<string, (state: State, action: Action) => State> = {
  CHANGE_TIMEFRAME: (state: State, action: ChangeTimeframeAction): State => {
    const { selectedCandle } = action.payload;
    return {
      ...state,
      selectedCandle,
    };
  },

  UPDATE_CANDLES: (state: State, action: UpdateCandlesAction): State => {
    const { candles } = action.payload;
    return {
      ...state,
      candles,
    };
  },

  UPDATE_USER_BALANCE: (
    state: State,
    action: UpdateUserBalanceAction
  ): State => {
    const { userBalance } = action.payload;
    return {
      ...state,
      userBalance,
    };
  },

  UPDATE_USER_ORDERS: (state: State, action: UpdateUserOrdersAction): State => {
    const { userOrders } = action.payload;
    return {
      ...state,
      userOrders,
    };
  },

  UPDATE_USER_LIVE_OPERATIONS: (
    state: State,
    action: UpdateLiveOperationsAction
  ): State => {
    const { userLiveOperations } = action.payload;
    return {
      ...state,
      userLiveOperations,
    };
  },

  UPDATE_CHART_ORDERS: (
    state: State,
    action: UpdateChartOrdersAction
  ): State => {
    const { chartOrders } = action.payload;
    return {
      ...state,
      chartOrders,
    };
  },

  UPDATE_TICKERBOOK_ACTION: (
    state: State,
    action: UpdateTickerBookAction
  ): State => {
    const { candleTimeFrame, ticker } = action.payload;
    let tickerBookRemovingCurrentTimeFrame: any[] = state.tickerBook?.filter(
      (it) => it.candleTimeFrame !== candleTimeFrame
    );
    if (!tickerBookRemovingCurrentTimeFrame) {
      tickerBookRemovingCurrentTimeFrame = [];
    }
    return {
      ...state,
      tickerBook: [
        ...tickerBookRemovingCurrentTimeFrame,
        { candleTimeFrame, ticker },
      ],
    };
  },

  UPDATE_SERVER_TIME: (
    state: State,
    action: UpdateServerTimeAction
  ): State => ({
    ...state,
    serverTime: action.payload.serverTime,
  }),

  SET_ORDER_VALUE: (state: State, action: SetOrderValueAction): State => ({
    ...state,
    orderValue: action.payload.orderValue,
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const extractCandleTimeFrameFromPayload = (data) =>
  data.payload.candleTimeFrame;

interface ApiContextValue extends State {
  updateTimeframe: (timeframe: string) => Promise<void>;
  updateCandles: (candles: Candle[]) => Promise<void>;
  updateUserBalance: (balance: number) => Promise<void>;
  updateUserOrders: (orders: Order[]) => Promise<void>;
  updateUserLiveOperatons: (
    orders: Order[],
    addOrdersToChart?: boolean
  ) => Promise<void>;
  addUserLiveOperatons: (order: Order) => Promise<void>;
  updateTickerBook: (candleTimeFrame: string, ticker: Ticker) => Promise<void>;
  getSelectedTickerBook: () => Ticker;
  updateServerTime: (serverTime: number) => void;
  handleBalanceEvent: (data: any) => void;
  handleUserOrdersEvent: (data: any) => void;
  handleTicker: (data: any) => void;
  handleCandleClose: (data: any) => void;
  handleWinLose: (data: any) => void;
  handleRefundedOrder: (data: any) => void;
  handleServerTime: (data: any) => void;
  handleRequestPix: (requestPix: RequestPix) => Promise<void>;
  setOrderValue: (orderValue: number) => void;
}

const ApiContext = createContext<ApiContextValue>({
  ...initialState,
  updateTimeframe: () => Promise.resolve(),
  updateCandles: () => Promise.resolve(),
  updateUserBalance: () => Promise.resolve(),
  updateUserOrders: () => Promise.resolve(),
  updateUserLiveOperatons: () => Promise.resolve(),
  addUserLiveOperatons: () => Promise.resolve(),
  updateTickerBook: () => Promise.resolve(),
  // updateUserBook: () => Promise.resolve(),
  getSelectedTickerBook: () => null,
  updateServerTime: () => null,
  handleBalanceEvent: () => null,
  handleUserOrdersEvent: () => null,
  handleTicker: () => null,
  handleCandleClose: () => null,
  handleWinLose: () => null,
  handleRefundedOrder: () => null,
  handleServerTime: () => null,
  handleRequestPix: () => null,
  setOrderValue: () => null,
});

export const ApiProvider: FC<ApiProviderProps> = (props) => {
  const { children } = props;
  const theme = useTheme();
  const query = useQuery();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [playOrderWin] = useSound(orderWinSound);
  const [playOrderLose] = useSound(orderLoseSound);

  const novo = query.get("novo");

  const [state, dispatch] = useReducer(reducer, initialState);
  const { tvWidget, addChartOrders, winLoseChartOrder } =
    useContext(TradingViewContext);
  const {
    setOrderHistoryTab,
    setModalRequireDocumentValidate,
    setModalOutsideMarket,
  } = useContext(LayoutContext);
  const { settings } = useContext(SettingsContext);
  const [defaultCandleTimeFrame, setDefaultCandleTimeFrame] = useLocalStorage(
    "defaultCandleTimeFrame",
    "M1"
  );
  const [defaultSymbol, setDefaultSymbol] = useLocalStorage(
    "defaultSymbol",
    "BTCUSDT"
  );
  const [time, setTime] = useState<any>(0);
  // const { enqueueSnackbar } = useSnackbar();
  const { user } = useUser();

  useEffect(() => {
    updateTimeframe(defaultCandleTimeFrame);
  }, []);

  const updateTimeframe = async (timeframe: string): Promise<void> => {
    setDefaultCandleTimeFrame(timeframe);
    dispatch({
      type: "CHANGE_TIMEFRAME",
      payload: {
        selectedCandle: timeframe,
      },
    });
  };

  const updateCandles = async (candles: Candle[]): Promise<void> => {
    dispatch({
      type: "UPDATE_CANDLES",
      payload: {
        candles,
      },
    });
  };

  const updateUserBalance = async (balance: number): Promise<void> => {
    dispatch({
      type: "UPDATE_USER_BALANCE",
      payload: {
        userBalance: balance,
      },
    });
  };

  const updateTickerBook = async (
    candleTimeFrame: string,
    ticker: Ticker
  ): Promise<void> => {
    dispatch({
      type: "UPDATE_TICKERBOOK_ACTION",
      payload: {
        candleTimeFrame,
        ticker,
      },
    });
  };

  const updateServerTime = (serverTime) => {
    dispatch({
      type: "UPDATE_SERVER_TIME",
      payload: {
        serverTime,
      },
    });
  };

  const updateUserBook = async (): Promise<void> => {
    dispatch({
      type: "UPDATE_USER_BOOK",
    });
  };

  const getSelectedTickerBook = (): Ticker => {
    const bla = state.tickerBook?.filter(
      (it) => it.candleTimeFrame === state.selectedCandle
    );
    if (bla !== undefined && bla.length > 0) {
      return bla[0].ticker;
    }
    return {
      volume: {
        green: 0,
        red: 0,
      },
      book: {
        green: 0,
        red: 0,
        volume: 0,
      },
    };
  };

  const handleBalanceEvent = (data) => {
    const balanceEvent = "user_balance";
    if (data.event === balanceEvent) {
      updateUserBalance(data.payload.usdt);
    }

    const ordersEvent = "user_orders";
    if (data.event === ordersEvent) {
      updateUserBook();
    }
  };

  const handleUserOrdersEvent = async (data) => {
    const ordersEvent = "user_orders";
    if (data.event === ordersEvent) {
      const ordersPendingAndOpen = orderBy(
        data.payload.pending.concat(data.payload.open),
        [(item) => new Date(item.createdAt)],
        ["asc"]
      );

      updateUserLiveOperatons(ordersPendingAndOpen);
    }
  };

  useEffect(() => {
    if (state.userLiveOperations) {
      setOrderHistoryTab(0);
    }
  }, []);

  const updateUserLiveOperatons = async (
    orders: Order[],
    addOrdersToChart = true
  ): Promise<void> => {
    const activeOrdesBySymbol = orders.filter(
      (o) => o.symbol === defaultSymbol
    );

    const ordersForTheChart = orders.filter(
      (o) =>
        o.symbol === defaultSymbol &&
        o.candleTimeFrame === defaultCandleTimeFrame
    );

    if (addOrdersToChart) {
      addChartOrders(ordersForTheChart, defaultCandleTimeFrame);
    }

    const newOrders = activeOrdesBySymbol.reduce((obj, item) => {
      // Se a ordem não existe ou o status é diferente, adicione/atualize a ordem
      if (
        !state.userLiveOperations[item.id] ||
        state.userLiveOperations[item.id].status !== item.status
      ) {
        obj[item.id] = item;
      } else {
        // Se a ordem existe e o status é o mesmo, mantenha a ordem existente
        obj[item.id] = state.userLiveOperations[item.id];
      }
      return obj;
    }, {});

    dispatch({
      type: "UPDATE_USER_LIVE_OPERATIONS",
      payload: {
        userLiveOperations: newOrders,
      },
    });
  };

  const addUserLiveOperatons = async (order: Order): Promise<void> => {
    const orders = state.userLiveOperations;
    orders[order.id] = order;

    const ordersOrdered = orderBy(
      Object.values(orders),
      [(item) => new Date(item.createdAt)],
      ["asc"]
    );

    updateUserLiveOperatons(ordersOrdered);
  };

  const updateUserOrders = async (orders: Order[]): Promise<void> => {
    dispatch({
      type: "UPDATE_USER_ORDERS",
      payload: {
        userOrders: orders,
      },
    });
  };

  const updateUserBookLala = async (): Promise<void> => {
    dispatch({
      type: "UPDATE_USER_BOOK",
    });
  };

  const handleTicker = (data) => {
    if (
      data.event === "ticker" &&
      state.selectedCandle === extractCandleTimeFrameFromPayload(data)
    ) {
      const tickerData = data.payload;
      updateTickerBook(state.selectedCandle, {
        volume: {
          green: tickerData.volume.green24,
          red: tickerData.volume.red24,
          volume: tickerData.volume.volume24,
        },
        book: {
          green: tickerData.book.green,
          red: tickerData.book.red,
        },
      });
    }
  };

  const handleCandleClose = (data) => {
    if (data.event.startsWith("candle_close")) {
      const candleNumber = extractCandleTimeFrameFromPayload(data);

      if (candleNumber === state.selectedCandle) {
        if (state.tickerBook) {
          const tickerbook = state.tickerBook.filter(
            (it) => it.candleTimeFrame === candleNumber
          )?.[0];

          updateTickerBook(state.selectedCandle, {
            volume: {
              green: tickerbook?.ticker.volume.green || 0,
              red: tickerbook?.ticker.volume.red || 0,
              volume: tickerbook?.ticker.volume.volume || 0,
            },
            book: {
              green: 0,
              red: 0,
            },
          });
        }
      }
    }
  };

  const handleWinLose = (data) => {
    const loseOrderEvent = `lose_order`;
    console.log("data.payload", data.payload);
    if (data.event === loseOrderEvent) {
      const result = `Resultado: Perda - ${formatCurrency(data.payload.value)}`;
      winLoseChartOrder(data.payload, "lose");
      notifyError(result);

      if (settings.isSoundOn) {
        playOrderLose();
      }
    }

    const winOrderEvent = `win_order`;
    if (data.event === winOrderEvent) {
      const result = `Resultado: Lucro + ${formatCurrency(data.payload.value)}`;
      winLoseChartOrder(data.payload, "win");
      notifySuccess(result);

      if (settings.isSoundOn) {
        playOrderWin();
      }
    }
  };

  const handleRefundedOrder = (data) => {
    const refundedOrderEvent = `refunded-order-${user.email}`;
    if (data.event === refundedOrderEvent) {
      //
    }
  };

  const handleServerTime = (data) => {
    const event = "server_time";
    if (data.event === event) {
      updateServerTime(data.payload.serverNowDate);
    }
  };

  const handleRequestPix = async (requestPix: RequestPix) => {
    try {
      const cpf = requestPix.cpf.replace(/\D/g, "");

      const amount = requestPix.brl.toString();

      delete requestPix.usdt;
      delete requestPix.brl;

      const data = await apiPost("bank/deposits/nox/pix", {
        ...requestPix,
        amount,
        cpf,
      });

      navigate(
        `/dashboard/profile?tab=deposit&depositId=${data.id}&gatewayTransactionType=pix`
      );
    } catch (error) {
      console.log("error", error);
      if (error === "UserMustBeVerifiedBeforeAskingForDepositException") {
        setModalRequireDocumentValidate(true);
      }
    }
  };

  const setOrderValue = (orderValue: number) => {
    dispatch({
      type: "SET_ORDER_VALUE",
      payload: {
        orderValue,
      },
    });
  };

  return (
    <ApiContext.Provider
      value={{
        ...state,
        updateTimeframe,
        updateCandles,
        updateUserBalance,
        updateTickerBook,
        updateUserOrders,
        updateUserLiveOperatons,
        addUserLiveOperatons,
        getSelectedTickerBook,
        updateServerTime,
        handleBalanceEvent,
        handleUserOrdersEvent,
        handleTicker,
        handleCandleClose,
        handleWinLose,
        handleRefundedOrder,
        handleServerTime,
        handleRequestPix,
        setOrderValue,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApiContext;
