import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useTheme } from "@mui/styles";
import PusingDot from "../lottie/PusingDot";

// const btcImg = require("src/assets/img/coins/btc.png").default;

type DefaultCoinChartProps = {
  name: string;
  image: string;
  ping?: boolean;
  center?: boolean;
};

export default function DefaultCoinChart({
  name,
  image,
  ping,
  center,
}: DefaultCoinChartProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: "center",
        alignSelf: "center",
        justifyContent: center ? "center" : "flex-start",
      }}
    >
      {/* <TimelineIcon
        sx={{ width: isMobile ? 12 : 20, height: isMobile ? 12 : 20 }}
      /> */}
      <img src={image} style={{ width: 20, height: 20 }} alt={name} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography color="white" fontSize={12}>
          {name}
        </Typography>

        {!isMobile && ping && (
          <Box sx={{ ml: 1, mt: 0.8 }}>
            <PusingDot />
          </Box>
        )}
      </Box>
    </Stack>
  );
}
