import { toInteger } from "lodash";
import { apiGet } from "../../services/apiService";
import { subscribeOnStream, unsubscribeOnStream } from "./streaming.js";

import allSymbols from "../../assets/data/allSymbols.json";

// import transactions from "../../assets/data/Transactions-1687796845471.json";
import { differenceInMinutes } from "date-fns";

const lastBarsCache = new Map();

const configurationData = {
  supports_marks: true,
  supports_timescale_marks: true,
  supports_time: true,
  supported_resolutions: ["1", "5", "15", "30", "1h", "1D"],
  exchanges: [
    {
      value: "Binance",
      name: "Binance",
      desc: "Binance",
    },
    {
      value: "FOREXCOM",
      name: "FOREXCOM",
      desc: "FOREXCOM",
    },
  ],
  symbols_types: [
    {
      name: "Crypto",
      value: "crypto",
    },
    {
      name: "Forex",
      value: "forex",
    },
  ],
};

let brokerVersusClientClockTimestampDiff = 0;

export function getBrokerNowTime() {
  return new Date().getTime() - brokerVersusClientClockTimestampDiff;
}

async function getAllSymbols() {
  // const data = await makeApiRequest('data/v3/all/exchanges');
  // let allSymbols = [];
  // const data = await makeApiRequest(
  //   'exchangeInfo?symbols=["BTCUSDT","EURUSDT"]'
  // );
  // let allSymbols = [];

  // for (const exchange of data.symbols) {
  //   if (exchange.status !== "TRADING") {
  //     continue;
  //   }
  //   const symbol = `${exchange.baseAsset}/${exchange.quoteAsset}`;

  //   allSymbols.push({
  //     description: symbol,
  //     exchange: "Binance",
  //     full_name: `Binance:${symbol}`,
  //     symbol: symbol,
  //     ticker: symbol.replace("/", ""),
  //     name: symbol.replace("/", ""),
  //     type: "crypto",
  //   });
  // }
  // console.log("allSymbols", allSymbols);
  // //
  // // for (const exchange of configurationData.exchanges) {
  // // 	const pairs = data.Data[exchange.value].pairs;
  // //
  // // 	for (const leftPairPart of Object.keys(pairs)) {
  // // 		const symbols = pairs[leftPairPart].map(rightPairPart => {
  // // 			const symbol = generateSymbol(exchange.value, leftPairPart, rightPairPart);
  // // 			return {
  // // 				symbol: symbol.short,
  // // 				full_name: symbol.full,
  // // 				description: symbol.short,
  // // 				exchange: exchange.value,
  // // 				type: 'crypto',
  // // 			};
  // // 		});
  // // 		allSymbols = [...allSymbols, ...symbols];
  // // 	}
  // // }
  // // return allSymbols;

  // // console.log(allSymbols);
  // return allSymbols;
  return allSymbols;
}

export default {
  onReady: async (callback) => {
    setTimeout(() => callback(configurationData));
  },

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    const symbols = await getAllSymbols();
    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === "" || symbol.exchange === exchange;
      const isFullSymbolContainsInput =
        symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });
    onResultReadyCallback(newSymbols);
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
    extension
  ) => {
    const symbols = await getAllSymbols();
    const symbolItem = symbols.find(({ ticker }) => ticker === symbolName);
    if (!symbolItem) {
      onResolveErrorCallback("cannot resolve symbol");
      return;
    }

    const symbolInfo = {
      ticker: symbolItem.ticker,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: symbolItem.session,
      timezone: symbolItem.timezone,
      exchange: symbolItem.exchange,
      listed_exchange: symbolItem.exchange,
      minmov: symbolItem.minmov,
      minmovement2: symbolItem.minmovement2,
      pricescale: symbolItem.pricescale,
      has_empty_bars: symbolItem.has_empty_bars,
      has_intraday: true,
      has_no_volume: true,
      volume_precision: symbolItem.volume_precision,
      has_weekly_and_monthly: false,
      supported_resolutions: configurationData.supported_resolutions,
      data_status: "streaming",
    };
    onSymbolResolvedCallback(symbolInfo);
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    const { from, to, firstDataRequest, countBack } = periodParams;
    let { description } = symbolInfo;
    description = description.replace("/", "");

    const startTime = from * 1000;
    const endTime = to * 1000;

    try {
      let interval = resolution.toLowerCase();

      if (!isNaN(interval)) {
        interval =
          parseInt(interval) >= 60 ? `H${interval / 60}` : `M${interval}`;
      }

      let data = await apiGet(
        `dataProvider/aggregatedTrades?symbol=${description}&candleTimeFrame=${interval}&from=${startTime}&to=${endTime}&limit=1000`
      );
      if ((data.Response && data.Response === "Error") || data.length === 0) {
        // "noData" should be set if there is no data in the requested period.
        onHistoryCallback([], {
          noData: true,
        });
        return;
      }

      const counterCandlesForTime = differenceInMinutes(endTime, startTime);

      const bars = [];

      for (let i = 0; i < data.length; ++i) {
        const barValue = {
          time: data[i].t,
          close: parseFloat(data[i].c),
          open: parseFloat(data[i].o),
          high: parseFloat(data[i].h),
          low: parseFloat(data[i].l),
        };

        bars.push(barValue);
      }

      // Verificar se a quantidade de candles é menor que countBack e o símbolo é "EURUSD"
      if (bars.length < countBack && description === "EURUSD") {
        const lastBar = bars[bars.length - 1];

        // Criar mais candles
        for (let i = bars.length; i < countBack; ++i) {
          const newBar = {
            close: lastBar.close,
            high: lastBar.close,
            isBarClosed: true,
            isLastBar: false,
            low: lastBar.close,
            open: lastBar.close,

            time: lastBar.time - (i + 1) * 60 * 1000, // Subtrair 1 minuto do tempo do último candle
          };

          bars.unshift(newBar);
        }
      }

      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.full_name, {
          ...bars[bars.length - 1],
        });
      }
      
      onHistoryCallback(bars, {
        noData: false,
      });
    } catch (error) {
      console.log("[getBars]: Get error", error);
      onErrorCallback(error);
    }
  },
  async getMarks(symbolInfo, startDate, endDate, onDataCallback, resolution) {},
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback
  ) => {
    console.log(
      "[subscribeBars]: Method call with subscriberUID:",
      subscriberUID
    );
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.full_name)
    );
  },

  unsubscribeBars: (subscriberUID) => {
    console.log(
      "[unsubscribeBars]: Method call with subscriberUID:",
      subscriberUID
    );
    unsubscribeOnStream(subscriberUID);
  },

  getServerTime: async (callback) => {
    //const start = window.performance.now();
    const start = new Date().getTime();
    const symbol = localStorage.getItem("defaultSymbol").replaceAll('"', "");
    const brokerTimestamp = await apiGet(
      `/orders/getBrokerTimestamp?symbol=${symbol}`
    );
    //const end = window.performance.now();
    const end = new Date().getTime();
    const requestTime = end - start;
    const nowTime = brokerTimestamp + toInteger(requestTime / 2);

    brokerVersusClientClockTimestampDiff = new Date().getTime() - nowTime;

    console.log(
      "brokerVersusClientClockTimestampDiff",
      brokerVersusClientClockTimestampDiff
    );

    const timeFix = 0;
    callback((nowTime + timeFix) / 1000);
  },
};
