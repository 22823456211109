export const appConfig = {
  api_url: process.env.REACT_APP_PUBLIC_API_URL,
  exchange_id: process.env.REACT_APP_EXCHANGE_ID,
  // websocket_url: process.env.REACT_APP_WEBSOCKET_URL
  websocket_url: process.env.REACT_APP_WEBSOCKET_URL,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const gtmConfig = {
  containerId: "G-P0EWS7HFEW",
};
