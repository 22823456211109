import { IconButton, Stack, Typography } from "@mui/material";
import numeral from "numeral";
import type { FC } from "react";
// import { useEffect } from 'react';
// import useAuth from '../../hooks/useAuth';
import useApiData from "../../hooks/useApiData";

const OrderBookSwitch: FC = () => {
  const { getSelectedTickerBook } = useApiData();

  const candleTimeFrameTickerBook = getSelectedTickerBook();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ p: 1, px: 2, backgroundColor: "#15181A" }}
    >
      <Typography
        align="center"
        fontWeight={400}
        color="white"
        fontSize={14}
        sx={{ color: "#00DB97" }}
      >
        {numeral(candleTimeFrameTickerBook.book.green).format("$0,0.00")}{" "}
        <small>USDT</small>
      </Typography>
      <IconButton disabled={true} color="primary" sx={{ color: "white" }} aria-label="SyncAlt">
        <img src="/static/icons/switch.svg" style={{ width: 20, height: 20 }} />
      </IconButton>
      <Typography
        align="center"
        fontWeight={400}
        color="white"
        fontSize={14}
        sx={{ color: "#FF5382" }}
      >
        {numeral(candleTimeFrameTickerBook.book.red).format("$0,0.00")}{" "}
        <small>USDT</small>
      </Typography>
    </Stack>
  );
};

export default OrderBookSwitch;
