import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Stack, Tab, Tabs, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";
import LayoutContext from "src/contexts/LayoutContext";
import { notifyError } from "src/utils/toast";

import { useTheme } from "@mui/styles";
import TradingViewContext from "src/contexts/TradingViewContext";
import useAvailableSymbols from "src/swr/use-available-symbols";
import { SymbolFilterData } from "src/types/filter";
import DefaultCoinChart from "../custom/coins/DefaultCoinChart";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    marginLeft: -4,
    minWidth: 220,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

function BpCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const symbols = [
  {
    description: "BTC/USDT",
    exchange: "Binance",
    full_name: "Binance:BTC/USDT",
    symbol: "BTC/USDT",
    ticker: "BTCUSDT",
    name: "BTCUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/BTCUSDT.png",
    icon: (
      <DefaultCoinChart
        name="BTC/USDT"
        image="https://ebinex-public.s3.sa-east-1.amazonaws.com/btc_usdt.png"
        ping={true}
      />
    ),
  },
  {
    description: "EUR/USD",
    exchange: "Binance",
    full_name: "Binance:EUR/USD",
    symbol: "EUR/USD",
    ticker: "EURUSD",
    name: "EURUSD",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/EURUSD.png",
    icon: (
      <DefaultCoinChart
        name="EUR/USD"
        image="https://ebinex-public.s3.sa-east-1.amazonaws.com/coin-eur-usd.png"
        ping={true}
      />
    ),
  },
  {
    description: "ETH/USDT",
    exchange: "Binance",
    full_name: "Binance:ETH/USDT",
    symbol: "ETH/USDT",
    ticker: "ETHUSDT",
    name: "ETHUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ETHUSDT.png",
    icon: (
      <DefaultCoinChart
        name="ETH/USDT"
        image="https://ebinex-public.s3.sa-east-1.amazonaws.com/coin-eth-usdt.png"
        ping={true}
      />
    ),
  },
  {
    description: "GBP/USD",
    exchange: "FX",
    full_name: "FX:GBP/USD",
    symbol: "GBP/USD",
    ticker: "GBPUSD",
    name: "GBPUSD",
    type: "forex",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/GBPUSD.png",
    icon: (
      <DefaultCoinChart
        name="GBP/USD"
        image="https://ebinex-public.s3.sa-east-1.amazonaws.com/coin-gbp-usd.png"
        ping={true}
      />
    ),
  },
  {
    description: "USD/CHF",
    exchange: "FX",
    full_name: "FX:USD/CHF",
    symbol: "USD/CHF",
    ticker: "USDCHF",
    name: "USDCHF",
    type: "forex",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDCHF.png",
    icon: (
      <DefaultCoinChart
        name="USD/CHF"
        image="https://ebinex-public.s3.sa-east-1.amazonaws.com/coin-usd-chf.png"
        ping={true}
      />
    ),
  },
  {
    description: "USD/JPY",
    exchange: "FX",
    full_name: "FX:USD/JPY",
    symbol: "USD/JPY",
    ticker: "USDJPY",
    name: "USDJPY",
    type: "forex",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDJPY.png",
    icon: (
      <DefaultCoinChart
        name="USD/JPY"
        image="https://ebinex-public.s3.sa-east-1.amazonaws.com/coin-usd-jpy.png"
        ping={true}
      />
    ),
  },
  {
    description: "BNB/USDT",
    exchange: "Binance",
    full_name: "Binance:BNB/USDT",
    symbol: "BNB/USDT",
    ticker: "BNBUSDT",
    name: "BNBUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/BNBUSDT.png",
    icon: (
      <DefaultCoinChart
        name="BNB/USDT"
        image="https://ebinex-public.s3.sa-east-1.amazonaws.com/coin-bnb-usdt.png"
        ping={true}
      />
    ),
  },
  {
    description: "SOL/USDT",
    exchange: "Binance",
    full_name: "Binance:SOL/USDT",
    symbol: "SOL/USDT",
    ticker: "SOLUSDT",
    name: "SOLUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/SOLUSDT.png",
    icon: (
      <DefaultCoinChart
        name="SOL/USDT"
        image="https://ebinex-public.s3.sa-east-1.amazonaws.com/coin-sol-usdt.png"
        ping={true}
      />
    ),
  },
  {
    description: "XRP/USDT",
    exchange: "Binance",
    full_name: "Binance:XRP/USDT",
    symbol: "XRP/USDT",
    ticker: "XRPUSDT",
    name: "XRPUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/XRPUSDT.png",
    icon: (
      <DefaultCoinChart
        name="XRP/USDT"
        image="https://ebinex-public.s3.sa-east-1.amazonaws.com/coin-xrp-usdt.png"
        ping={true}
      />
    ),
  },
  {
    description: "ADA/USDT",
    exchange: "Binance",
    full_name: "Binance:ADA/USDT",
    symbol: "ADA/USDT",
    ticker: "ADAUSDT",
    name: "ADAUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ADAUSDT.png",
    icon: (
      <DefaultCoinChart
        name="ADA/USDT"
        image="https://ebinex-public.s3.sa-east-1.amazonaws.com/coin-ada-usdt.png"
        ping={true}
      />
    ),
  },
];

export default function MenuSelectSymbol() {
  const { t } = useTranslation("dashboard");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { symbols: symbolsList } = useAvailableSymbols();
  const { setModalOutsideMarket } = React.useContext(LayoutContext);
  const { tvWidget, selectedSymbol, updateSymbol, removeOrdersBySymbol } =
    React.useContext(TradingViewContext);
  const [activeSymbol, setActiveSymbol] = React.useState<any>({});
  const [currentSymbolType, setCurrentSymbolType] =
    React.useState<SymbolFilterData["symbolType"]>("FOREX");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const symbolActive = symbols.find((s) => s.name === selectedSymbol);

    const symbolType = symbolActive?.type === "crypto" ? "CRYPTO" : "FOREX";
    setCurrentSymbolType(symbolType);

    setActiveSymbol(symbolActive);
  }, [selectedSymbol]);

  const handleSwitchSymbol = (item: SymbolFilterData) => {
    try {
      if (item.marketStatus !== "OPEN") {
        setModalOutsideMarket(
          true,
          `O gráfico do índice ${item.symbol} está fechado no momento.`,
          false
        );
        return;
      }
      localStorage.removeItem("currentChartAnalysis");
      updateSymbol(item.symbol);
      removeOrdersBySymbol(item.symbol);
      tvWidget.activeChart().setSymbol(item.symbol);
      handleClose();
      // window.location.reload();
    } catch (error) {
      notifyError("Oops! Não foi possível efetuar a operação.");
    }
  };

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        style={{ position: "relative", backgroundColor: "#3a3a3a4d" }}
        sx={{ px: isMobile ? 1 : undefined, height: isMobile ? 42 : undefined }}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {/* <Chip
          label={t("new")}
          size="small"
          color="error"
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            transform: "translate(40%, -30%)",
          }}
        /> */}
        <Stack
          direction={"column"}
          justifyContent="center"
          alignItems={"start"}
        >
          {activeSymbol.icon}
        </Stack>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
          sx: {
            border: "1px rgba(255, 255, 255, 0.2) solid",
            borderRadius: 2,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Tabs
          value={currentSymbolType}
          onChange={(_, newValue) => {
            setCurrentSymbolType(newValue);
          }}
        >
          <Tab
            label={t("coins")}
            value="FOREX"
            sx={{
              fontSize: 13,
              textTransform: "none",
            }}
          />
          <Tab
            label={t("crypto")}
            value="CRYPTO"
            sx={{
              fontSize: 13,
              textTransform: "none",
            }}
          />
        </Tabs>
        {symbolsList.map(
          (item) =>
            item.symbolType === currentSymbolType && (
              <MenuItem
                key={item.symbol}
                disableRipple
                onClick={() => handleSwitchSymbol(item)}
                sx={{
                  color:
                    item.marketStatus !== "OPEN"
                      ? "#919eabb3 !important"
                      : null,
                  backgroundColor:
                    item.marketStatus !== "OPEN"
                      ? "#27272740 !important"
                      : null,
                }}
              >
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <BpCheckbox checked={selectedSymbol === item.symbol} />
                  {symbols.find((s) => s.name === item.symbol) && (
                    <img
                      src={symbols.find((s) => s.name === item.symbol)?.image}
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 5,
                      }}
                      alt={`${new Date().getTime()}-imge`}
                    />
                  )}

                  {item.symbol}
                </Stack>
              </MenuItem>
            )
        )}
      </StyledMenu>
    </>
  );
}
