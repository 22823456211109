import PropTypes from "prop-types";
import { FC, ReactNode, createContext, useState } from "react";
import { OrderBookItem } from "../types/order";

interface State {
  bookRed: { candleTimeFrame: string; orderBook: OrderBookItem[] }[]; // @deprecated
  bookGreen: { candleTimeFrame: string; orderBook: OrderBookItem[] }[]; // @deprecated
}

interface ApiProviderProps {
  children: ReactNode;
}

const extractCandleTimeFrameFromPayload = (data) =>
  data.payload.candleTimeFrame;

interface OrderBookContextValue extends State {
  handleCurrentBook: (data: any) => void;
  clearBook: (data: any) => void;
}

const OrderBookContext = createContext<OrderBookContextValue>({
  bookRed: [],
  bookGreen: [],
  handleCurrentBook: () => null,
  clearBook: () => null,
});

export const OrderBookProvider: FC<ApiProviderProps> = (props) => {
  const { children } = props;
  const [bookRed, setBookRed] = useState([]);
  const [bookGreen, setBookGreen] = useState([]);

  const handleCurrentBook = (data) => {
    if (data.event.startsWith("book")) {
      const candleTimeFrame = extractCandleTimeFrameFromPayload(data);

      const bull = data.payload?.bull || [];
      const bear = data.payload?.bear || [];
      const newBookGreen = bookGreen.filter(
        (it) => it.candleTimeFrame !== candleTimeFrame
      );
      setBookGreen([...newBookGreen, { candleTimeFrame, orderBook: bull }]);
      const newBookRed = bookGreen.filter(
        (it) => it.candleTimeFrame !== candleTimeFrame
      );
      setBookRed([...newBookRed, { candleTimeFrame, orderBook: bear }]);
    }
  };

  const clearBook = (data) => {
    const candleNumber = extractCandleTimeFrameFromPayload(data);

    const newBookGreen = bookGreen.filter(
      (it) => it.candleTimeFrame !== candleNumber
    );
    setBookGreen(newBookGreen);
    const newBookRed = bookRed.filter(
      (it) => it.candleTimeFrame !== candleNumber
    );
    setBookRed(newBookRed);
  };

  return (
    <OrderBookContext.Provider
      value={{
        bookRed,
        bookGreen,
        handleCurrentBook,
        clearBook,
      }}
    >
      {children}
    </OrderBookContext.Provider>
  );
};

OrderBookProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrderBookContext;
