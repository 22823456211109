import { InfoRounded } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Link,
  Paper,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/styles";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";

type DepositCryptoFormProps = {
  depositDetail?: any;
};

export default function DepositCryptoForm({
  depositDetail,
}: DepositCryptoFormProps) {
  const { t } = useTranslation("dashboard");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const paymentUrl = depositDetail?.gatewayCustomData?.internalData?.paymentUrl;

  return (
    <>
      {depositDetail ? (
        <Paper sx={{ borderRadius: 2, mb: 4 }}>
          <Stack direction={"column"}>
            <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  sx={{ mt: 3, textAlign: "center" }}
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                >
                  <Box sx={{ p: isMobile ? 2 : 0, textAlign: "center", mb: 2 }}>
                    <Typography
                      variant="caption"
                      sx={{ color: "#9C9C9C" }}
                      fontWeight={400}
                      fontSize={isMobile ? 8 : 14}
                    >
                      {t("scan_qr_paymant_crypto")}
                    </Typography>
                  </Box>
                  <QRCode
                    value={paymentUrl}
                    size={180}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    level="L"
                    includeMargin
                    renderAs="svg"
                  />
                  {!paymentUrl && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width={180}
                      height={180}
                      sx={{
                        backgroundColor: "#d4b3b0",
                        margin: "0 auto",
                      }}
                    >
                      <InfoRounded fontSize="large" color="error" />
                    </Box>
                  )}
                </Box>

                <Box>
                  <Box sx={{ mt: 3, textAlign: "center" }}>
                    <Typography
                      sx={{ color: "#9C9C9C" }}
                      fontWeight={400}
                      fontSize={isMobile ? 8 : 14}
                    >
                      {t("click_link_paymant_crypto")}
                    </Typography>
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    sx={{
                      mt: 1,
                      p: 1,
                      backgroundColor: "rgba(255, 255, 255, 0.04)",
                    }}
                  >
                    <Stack direction="row" alignItems={"center"}>
                      <Link
                        href={paymentUrl}
                        target="_blank"
                        rel="noreferrer"
                        color="primary"
                        underline="hover"
                      >
                        <Typography
                          sx={{ color: "#9C9C9C" }}
                          fontWeight={400}
                          fontSize={isMobile ? 8 : 14}
                          color="textPrimary"
                          variant="body2"
                        >
                          {paymentUrl}
                        </Typography>
                      </Link>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}
