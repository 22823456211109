import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { createStyles, makeStyles, useTheme } from "@mui/styles";
import { FC, useContext, useEffect, useMemo, useState } from "react";
// import MiniWallet from '../../widgets/trade/MiniWallet';
import { useTranslation } from "react-i18next";
import CountdonwContext from "src/contexts/CountdownContext";
import LayoutContext from "src/contexts/LayoutContext";
import TradingViewContext from "src/contexts/TradingViewContext";
import useParameters from "src/swr/use-parameters";
import formatCurrency from "src/utils/formatCurrency";
import { NumericOnly } from "src/utils/numericOnly";
import { notifyError, notifySuccess } from "src/utils/toast";
import useSound from "use-sound";
import useApiData from "../../../hooks/useApiData";
import { apiPost } from "../../../services/apiService";
import TimeframeSelect from "../utils/TimeframeSelect";

import orderClick from "src/assets/audio/notifications/mixkit-cool-interface-click-tone-2568.wav";
import SettingsContext from "src/contexts/SettingsContext";

const useStyles = makeStyles(
  createStyles({
    input: {
      fontWeight: 400,
      fontSize: "1.2rem",
      backgroundColor: "#1F2225",
      paddingLeft: 20,
    },
    buttonUp: {
      width: 80,
      minHeight: 65,
      backgroundColor: "#08C58A",
      "&:hover": {
        backgroundColor: "#087a3d",
      },
    },
    buttonDown: {
      width: 80,
      minHeight: 65,
      backgroundColor: "#FF025C",
      "&:hover": {
        backgroundColor: "#c23535",
      },
    },
  })
);

const PlaceOrderForm: FC = () => {
  const { t } = useTranslation("dashboard"); // Add this
  const { parameters } = useParameters();
  const [amount, setAmount] = useState<string>("0");
  const [balance, setBalance] = useState(0);
  const [fee, setFee] = useState(0);
  const [playOrderClick] = useSound(orderClick);

  const {
    selectedCandle,
    userBalance,
    orderValue,
    addUserLiveOperatons,
    setOrderValue,
  } = useApiData();
  const { setOrderHistoryTab } = useContext(LayoutContext);
  const { tvWidget, selectedSymbol, winLoseChartOrder } =
    useContext(TradingViewContext);
  const { count } = useContext(CountdonwContext);
  const { settings } = useContext(SettingsContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile2 = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (parameters) {
      setFee(parameters?.FEE_RATE?.value);
    }
  }, [parameters]);

  useEffect(() => {
    increaseAmount(orderValue);
  }, []);

  const increaseAmount = (orderValue?: number) => {
    if (orderValue) {
      setAmount(orderValue.toString());
      return;
    }

    const newAmount = parseFloat(amount) + 1;
    setOrderValue(newAmount);
    setAmount(newAmount.toString());
  };

  const decreaseAmount = () => {
    if (parseFloat(amount) < 2) return;
    const newAmount = parseFloat(amount) - 1;
    setOrderValue(newAmount);
    setAmount(newAmount.toString());
  };

  const calculatePercentageIncome = () => {
    const numericAmount = parseFloat(amount);
    if (numericAmount === 0 || userBalance === 0) return 0;
    const percentageIncome = ((income - numericAmount) * 100) / numericAmount;
    if (Number.isNaN(percentageIncome)) {
      return 0;
    }
    return percentageIncome.toFixed(0);
  };

  const placeOrder = async (type: string): Promise<void> => {
    if (parseFloat(amount) > balance) {
      await notifyError("Quantidade não disponível em carteira.");
      return;
    }

    try {
      const direction = type.toUpperCase();
      const invest = parseFloat(amount);

      //@ts-ignore
      const { _items, _end } = tvWidget.activeChart().getSeries().data().bars();
      const candle = _items[_end - 1].value;

      const order = await apiPost("/orders", {
        candleTimeFrame: selectedCandle,
        symbol: selectedSymbol,
        direction,
        invest,
        asset: parameters?.DEFAULT_COIN?.value.toUpperCase(),
        price: candle[4],
      });
      console.log("new order", order);

      // addUserLiveOperatons(order);

      setOrderHistoryTab(0);
      if (!isMobile) {
        // winLoseChartOrder(
        //   {
        //     candleStartTime: 1725481800000,
        //     orderDirection: "BULL",
        //     ordersIds: ["66d8c32ae9a0e86984388235"],
        //     price: 2450.62,
        //     value: 500,
        //   },
        //   "win"
        // );
        notifySuccess("Ordem criada com sucesso!");
      }

      if (settings.isSoundOn) {
        playOrderClick();
      }

      // showSuccess('Ordem criada com sucesso!');
      // setAmount(0); // zera form
    } catch (err) {
      console.log(err);
      if (err === "Locked window") {
        notifyError("Aguarde alguns segundos para fazer o envio da ordem");
        return;
      } else if (err === "SimultaneousRequestsException") {
        notifyError(
          "Várias ordens detectadas em sequência. Aguarde e tente novamente."
        );
        return;
      }
      notifyError("Oops! Não foi possível enviar a ordem.");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (NumericOnly(e)) {
      setAmount(e.target.value === "" ? "" : e.target.value);
      setOrderValue(parseFloat(e.target.value));
    }
  };

  const handleBlur = () => {
    if (amount === "") {
      setAmount("1");
      setOrderValue(1);
    }
  };

  useEffect(() => {
    setBalance(userBalance);
  }, [setBalance, setAmount, userBalance]);

  const income = useMemo(() => {
    const numericAmount = parseFloat(amount);
    const estimateIncome = numericAmount * 2 - numericAmount * (fee / 100);
    return parseFloat(estimateIncome.toFixed(3));
  }, [amount, fee]);

  return (
    <Box
      sx={{
        minHeight: "100%",
        bgcolor: "#00000080",
        borderRadius: 1,
        px: 2,
      }}
    >
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 12, sm: 8, md: 12 }}
      >
        <Grid item xs={isMobile ? 6 : 12} sx={{ my: isMobile ? 0 : 2 }}>
          <TimeframeSelect />
        </Grid>
        <Grid item xs={isMobile ? 6 : 12} sx={{ mb: isMobile ? 0.5 : 0 }}>
          <Stack direction="column" spacing={1}>
            {!isMobile && (
              <Typography
                color={"#5A5A5A"}
                fontWeight={600}
                fontSize={"0.8rem"}
                align={isMobile ? "center" : "left"}
              >
                {t("value")} U$
              </Typography>
            )}
            <TextField
              sx={{
                fontWeight: 400,
                fontSize: "1rem",
                backgroundColor: "#1F2225",
              }}
              size={isMobile ? "small" : "medium"}
              value={amount}
              label={isMobile ? `${t("value")} U$` : undefined}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={decreaseAmount}
                      edge="start"
                    >
                      <img
                        src="/static/icons/less-btn.svg"
                        style={{ width: 25, height: 25 }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      backgroundColor: "#15181A",
                    }}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => increaseAmount()}
                      edge="end"
                    >
                      <img
                        src="/static/icons/plus-btn.svg"
                        style={{ width: 25, height: 25 }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          {isMobile && (
            <Stack
              direction="column"
              justifyContent="center"
              spacing={2}
              marginTop={0.7}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "top",
                    gap: "2px",
                  }}
                >
                  <Typography
                    color="#ffffff"
                    fontWeight={400}
                    variant="caption"
                  >
                    {t("performance")}
                  </Typography>
                </Box>
                {isMobile && (
                  <Typography
                    color="primary"
                    fontSize={14}
                    fontWeight={600}
                    sx={{ ml: 0.4 }}
                  >
                    {`+ ${calculatePercentageIncome()}%`}
                  </Typography>
                )}
                <Typography
                  color="primary"
                  fontWeight={600}
                  variant="caption"
                  fontSize={14}
                  sx={{ lineHeight: 1 }}
                >
                  {`${isMobile ? "- " : "+ "}${formatCurrency(
                    income - parseInt(amount)
                  )}`}
                </Typography>
              </Box>
            </Stack>
          )}
        </Grid>
      </Grid>
      <Grid item sx={{ my: isMobile ? 1 : 2, pb: isMobile ? 0 : "16px" }}>
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          {!isMobile && (
            <Grid item xs={isMobile ? 12 : 6}>
              <Stack direction="column" justifyContent="center" spacing={2}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "row" : "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "top",
                      gap: "2px",
                    }}
                  >
                    <Typography
                      color="#ffffff"
                      fontWeight={400}
                      variant="caption"
                      fontSize={isMobile2 ? "0.8rem" : "1rem"}
                    >
                      {t("performance")}
                    </Typography>
                  </Box>
                  {isMobile && (
                    <Typography
                      color="primary"
                      fontSize={isMobile2 ? "1rem" : "1.6rem"}
                      fontWeight={600}
                      sx={{ ml: 0.4 }}
                    >
                      {`+ ${calculatePercentageIncome()}%`}
                    </Typography>
                  )}
                  {!isMobile && (
                    <Typography
                      color="primary"
                      fontSize={isMobile2 ? "1rem" : "1.6rem"}
                      fontWeight={600}
                      sx={{ lineHeight: 1 }}
                    >
                      {`${calculatePercentageIncome()}%`}
                    </Typography>
                  )}
                  <Typography
                    color="primary"
                    fontWeight={600}
                    variant="caption"
                    fontSize={"1rem"}
                    sx={{ lineHeight: 1 }}
                  >
                    {`${isMobile ? "- " : "+ "}${formatCurrency(
                      income - parseInt(amount)
                    )}`}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          )}
          <Grid
            item
            xs={isMobile ? 12 : 6}
            sx={{ paddingTop: isMobile ? "8px !important" : undefined }}
          >
            <Stack
              direction={isMobile ? "row" : "column"}
              spacing={isMobile ? 2 : 1}
              justifyContent="space-between"
              alignContent={"end"}
            >
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  minHeight: isMobile2 ? 40 : 60,
                  backgroundColor: "#12a577",
                  "&:hover": {
                    backgroundColor: "#06734D",
                  },
                }}
                onClick={() => placeOrder("bull")}
                disabled={parseInt(amount) === 0 || count < 6}
              >
                <Stack
                  direction={isMobile ? "row" : "column"}
                  justifyContent={isMobile ? "space-around" : "center"}
                  alignItems={"center"}
                >
                  <Typography
                    fontSize="0.8rem"
                    fontWeight="600"
                    sx={{ mr: isMobile ? 8 : null }}
                  >
                    {t("bull")}
                  </Typography>
                  <TrendingUpIcon fontSize={isMobile ? "large" : "medium"} />
                </Stack>
              </Button>
              <Button
                sx={{
                  width: "100%",
                  minHeight: isMobile2 ? 40 : 60,
                  backgroundColor: "#FF025C",
                  "&:hover": {
                    backgroundColor: "#CC024D",
                  },
                }}
                variant="contained"
                onClick={() => placeOrder("bear")}
                disabled={parseInt(amount) === 0 || count < 6}
              >
                <Stack
                  direction={isMobile ? "row" : "column"}
                  justifyContent={isMobile ? "space-around" : "center"}
                  alignItems={"center"}
                >
                  <Typography
                    fontSize="0.8rem"
                    fontWeight="600"
                    sx={{ mr: isMobile ? 8 : null }}
                  >
                    {t("bear")}
                  </Typography>
                  <TrendingDownIcon fontSize={isMobile ? "large" : "medium"} />
                </Stack>
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlaceOrderForm;
