import CachedIcon from "@mui/icons-material/Cached";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import numeral from "numeral";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AccountContext from "src/contexts/AccountContext";
import LayoutContext from "src/contexts/LayoutContext";
import useApiData from "src/hooks/useApiData";
import useAuth from "src/hooks/useAuth";
import { apiPost } from "src/services/apiService";
import { Account } from "src/types";
import { notifyError, notifySuccess } from "src/utils/toast";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    border: "1px rgba(255, 255, 255, 0.2) solid",
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 220,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

function BpCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const accountId = window.localStorage.getItem("accountId");

export default function MenuSelectAccount() {
  const { t } = useTranslation("dashboard");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { setModalAlertAccountReal, setModalAlertAccountDemo } =
    React.useContext(LayoutContext);
  const { handleBalanceEvent } = useApiData();
  const { switchAccount, initialize } = useAuth();
  const { accounts, activeAccount, setActiveAccount, mutate } =
    React.useContext(AccountContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const accountActive = accounts.find((c: any) => c.id === accountId);

    if (window.location.pathname === "/traderoom") {
      const closedModalReal = localStorage.getItem("closeModalReal");
      const closedModalDemo = localStorage.getItem("closeModalDemo");

      if (accountActive) {
        if (accountActive?.label === "DEMO") {
          if (!closedModalDemo || closedModalDemo !== "true") {
            setModalAlertAccountDemo(true);
          }
        }
        if (accountActive?.label === "REAL") {
          if (!closedModalReal || closedModalReal !== "true") {
            setModalAlertAccountReal(true);
          }
        }
      }
    }

    handleBalanceEvent({
      event: "user_balance",
      payload: {
        usdt: accountActive.defaultCoinBalance,
      },
    });
  }, []);

  const handleSwitchAccount = async (account: Account) => {
    try {
      await switchAccount(account);
      mutate("/users/listAccounts");
      mutate("/bank/balanceComplete")
      setActiveAccount(account);
      notifySuccess(`Conta alterada com sucesso`);

      // if (type === "DEMO") {
      //   window.location.href = "/traderoom";
      // } else {
      //   window.location.reload();
      // }
    } catch (error) {
      await notifyError("Oops! Não foi possível efetuar a operação.");
    }
  };

  const addTestFunds = async () => {
    try {
      await apiPost("/bank/addTestFunds", {});
      notifySuccess(`Adicionado saldo com sucesso`);
      await initialize();
    } catch (error) {
      await notifyError("Oops! Não foi possível efetuar a operação.");
    }
  };

  const splitName = (value, index) => {
    return value?.split(" - ")[index];
  };

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        style={{ backgroundColor: "#3a3a3a4d" }}
        sx={{ py: isMobile ? 0.5 : undefined, px: isMobile ? 1.5 : undefined }}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <Stack
          direction={"column"}
          justifyContent="center"
          alignItems={"start"}
        >
          <Typography fontSize={10} fontWeight={600} whiteSpace="nowrap">
            {t("account")} {splitName(activeAccount.label, 0)}
          </Typography>
          <Typography whiteSpace="nowrap">
            U$ {numeral(activeAccount.defaultCoinBalance).format("0,0.00")}
          </Typography>
        </Stack>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {accounts.map((account) => {
          if (account.environment === "REAL") {
            return (
              <MenuItem
                key={account.id}
                disableRipple
                onClick={() => handleSwitchAccount(account)}
              >
                <Stack direction="row" spacing={2}>
                  <BpCheckbox checked={activeAccount?.id === account.id} />
                  <Box>
                    <Typography>{`${
                      account.label.split("U$")[0]
                    } `}</Typography>
                    <Typography>
                      U$ ${numeral(account.defaultCoinBalance).format("0,0.00")}
                    </Typography>
                  </Box>
                </Stack>
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                key={account.id}
                disableRipple
                onClick={() => handleSwitchAccount(account)}
              >
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  spacing={2}
                >
                  <BpCheckbox checked={activeAccount?.id === account.id} />
                  <Box>
                    <Typography>{`${
                      account.label.split("U$")[0]
                    } `}</Typography>
                    <Typography>
                      U$ ${numeral(account.defaultCoinBalance).format("0,0.00")}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      aria-label="delete"
                      size="medium"
                      onClick={() => addTestFunds()}
                      disabled={!(activeAccount?.id === account.id)}
                    >
                      <CachedIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </Stack>
              </MenuItem>
            );
          }
        })}
      </StyledMenu>
    </>
  );
}
