import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  Modal,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import LayoutContext from "src/contexts/LayoutContext";
import { Close, Mail, Telegram } from "@mui/icons-material";
import Logo from "../Logo";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 550,
      border: "1px rgba(255, 255, 255, 0.2) solid",
    },
    chip: {
      width: 18,
      height: 18,
      backgroundColor: "rgba(145, 158, 171, 0.24)",
      borderRadius: 0.51,
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  })
);

function AlertAccountDemoModal() {
  const { t } = useTranslation("dashboard");
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { layout, setModalAlertAccountDemo } = useContext(LayoutContext);
  const [checked, setCheked] = useState(false);

  const handleCloseModalDemo = () => {
    if (checked) localStorage.setItem("closeModalDemo", "true");
    setModalAlertAccountDemo(false);
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={layout.modalAlertAccountDemo}
      onClose={() => handleCloseModalDemo()}
    >
      <Paper
        sx={{ p: 3, minWidth: isMobile ? "80%" : null }}
        className={classes.paper}
      >
        <IconButton
          aria-label="close"
          onClick={() => handleCloseModalDemo()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <Stack direction="row" spacing={2}>
          <Stack direction={"column"} spacing={2}>
            <Typography fontSize={16} fontWeight="bold" textAlign={"center"}>
              Você está na conta demo.
            </Typography>
            <Typography fontSize={14} fontWeight={300} textAlign={"center"}>
              A conta demonstrativa é muito interessante para que você se
              familiarize com a nossa plataforma e possa testar livremente as
              suas estratégias.
            </Typography>
            <Typography fontSize={14} fontWeight={300} textAlign={"center"}>
              As operações na demo não envolvem dinheiro real, e você pode
              recarregar o seu saldo fictício sempre que precisar.
            </Typography>
            <FormGroup sx={{ pt: 1 }}>
              <FormControlLabel
                checked={checked}
                onChange={() => setCheked(!checked)}
                control={<Checkbox />}
                sx={{ fontSize: 12, color: "#cfcfcf" }}
                label="Não mostrar essa mensagem novamente"
              />
            </FormGroup>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={(e: any) => handleCloseModalDemo()}
              fullWidth
            >
              {t("operate_on_demo_account")}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}

export default AlertAccountDemoModal;
