import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import OrderBook from "./OrderBook";
import OrderHistory from "./OrderHistory";
import PlaceOrder from "./PlaceOrder";

export default function TradeOrder() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        width: isMobile ? "100%" : 300,
      }}
    >
      <PlaceOrder />
      {!isMobile && <OrderBook />}
      {isMobile && <OrderHistory page="overview" isSimplified />}^
      {isMobile && <OrderBook />}
    </Box>
  );
}
