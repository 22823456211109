import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { createContext, useEffect, useReducer, useRef, useState } from "react";
import {
  EntityId,
  IChartingLibraryWidget,
} from "src/charting_library/charting_library";
import { getBrokerNowTime } from "src/components/TVChartContainer/datafeed";
import useParameters from "src/swr/use-parameters";
import { Order, OrderStatusEnum } from "src/types/order";
import { convertTimeToCandlePoint } from "src/utils/candle";
import { delay } from "src/utils/delay";
import formatCurrency from "src/utils/formatCurrency";
import { getResulteOnLive } from "src/utils/getResultOnLine";
import { useLocalStorage } from "usehooks-ts";

type ChartOrders = { id: string; entities: EntityId[] };

type GroupedOrders = {
  id: string;
  orders: Order[];
};

type WinLoseOrderType = {
  candleStartTime: number;
  orderDirection: string;
  price: number;
  ordersIds: string[];
  symbol: string;
  value: number;
};

type LastDailyBar = {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
};

interface OrderItem {
  order: any;
  orderId: string;
  direction: "buy" | "sell";
  timeframe: string;
  symbol: string;
  status: string;
}

interface State {
  tvWidget: IChartingLibraryWidget | null;
  widgetReady: boolean;
  chartOrders: ChartOrders[];
  eventsPending: any[];
  candleTime: number;
  lastDailyBar: LastDailyBar;
  selectedSymbol: string;
  orders: OrderItem[];
}

interface BollingerBands {
  sma: number;
  upperBand: number;
  lowerBand: number;
  maxHeight: number;
}

export interface TradingViewContextValue extends State {
  setWidget: (tvWidget: IChartingLibraryWidget) => void;
  addChartOrders: (orders: Order[], currentTimeframe: string) => void;
  setChartOrders: (chartOrders: ChartOrders[]) => void;
  removeChartOrders: (orders: GroupedOrders[]) => void;
  winLoseChartOrder: (orders: WinLoseOrderType, status: string) => void;
  removeAllCallouts: () => void;
  setCandleTime: (timestamp: number) => void;
  updateLastDialyBar: (lastDailyBar: LastDailyBar) => void;
  addEventPending: (event: any) => void;
  removeEventPending: () => void;
  updateSymbol: (symbol: string) => void;
  closeOrderById: (orderId: string) => void;
  closeAllOrders: () => void;
  removeOrderById: (orderId: string) => void;
  removeOrdersBySymbol: (symbol: string) => void;
}

interface TradingViewProviderProps {
  children?: ReactNode;
}

const initialState: State = {
  tvWidget: null,
  widgetReady: false,
  chartOrders: [],
  eventsPending: [],
  candleTime: 0,
  selectedSymbol: "BTCUSDT",
  orders: [],
  lastDailyBar: {
    time: 0,
    open: 0,
    high: 0,
    low: 0,
    close: 0,
  },
};

type Action =
  | { type: "SET_TV_WIDGET"; payload: IChartingLibraryWidget }
  | { type: "SET_CHART_ORDERS"; payload: ChartOrders[] }
  | { type: "SET_EVENTS_PENDING"; payload: any }
  | { type: "REMOVE_EVENTS_PENDING"; payload: any }
  | { type: "SET_CANDLE_TIME"; payload: number }
  | { type: "UPDATE_LAST_DAILY_BAR"; payload: LastDailyBar }
  | { type: "SET_SELECTED_SYMBOL"; payload: string }
  | { type: "ADD_ORDER"; payload: OrderItem }
  | { type: "REMOVE_ORDER_BY_ID"; payload: string }
  | { type: "UPDATE_ORDER"; payload: OrderItem };

const handlers: Record<string, (state: State, action: Action) => State> = {
  SET_TV_WIDGET: (state, action) => ({
    ...state,
    tvWidget: action.payload,
  }),
  SET_CHART_ORDERS: (state, action) => ({
    ...state,
    chartOrders: action.payload,
  }),
  SET_EVENTS_PENDING: (state, action) => ({
    ...state,
    eventsPending: [...state.eventsPending, action.payload],
  }),
  REMOVE_EVENTS_PENDING: (state) => ({
    ...state,
    eventsPending: [],
  }),
  UPDATE_LAST_DAILY_BAR: (state, action) => ({
    ...state,
    lastDailyBar: action.payload,
  }),
  SET_SELECTED_SYMBOL: (state, action) => ({
    ...state,
    selectedSymbol: action.payload,
  }),
  ADD_ORDER: (state, action) => ({
    ...state,
    orders: [...state.orders, action.payload],
  }),
  REMOVE_ORDER_BY_ID: (state, action) => {
    const updatedOrders = state.orders.filter(
      (i) => i.orderId !== action.payload
    );

    const orderToRemove = state.orders.find(
      (i: any) => i.orderId === action.payload
    );

    if (orderToRemove) {
      console.log("Removing order: ", orderToRemove.orderId);
      console.log("Removing order: ", orderToRemove.order._line);
      console.log("List order: ", tvWidgetLocal.activeChart().getAllShapes());
      tvWidgetLocal.activeChart().removeEntity(orderToRemove.order._line._id);
    }
    return {
      ...state,
      orders: updatedOrders,
    };
  },
  UPDATE_ORDER: (state, action) => ({
    ...state,
    orders: state.orders.map((i) =>
      i.orderId === action.payload.orderId ? action.payload : i
    ),
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

let tvWidgetLocal: IChartingLibraryWidget;

const orderIntervals = new Map<string, NodeJS.Timeout>();

const TradingViewContext = createContext<TradingViewContextValue>({
  ...initialState,
  setWidget: () => {},
  addChartOrders: () => null,
  setChartOrders: () => null,
  removeChartOrders: () => null,
  winLoseChartOrder: () => null,
  removeAllCallouts: () => null,
  setCandleTime: () => null,
  updateLastDialyBar: () => null,
  addEventPending: () => null,
  removeEventPending: () => null,
  updateSymbol: () => null,
  closeOrderById: () => null,
  closeAllOrders: () => null,
  removeOrderById: () => null,
  removeOrdersBySymbol: () => null,
});

const winLoseStatus = [
  OrderStatusEnum.WIN.toUpperCase(),
  OrderStatusEnum.LOSE.toUpperCase(),
];

export const TradingViewProvider: FC<TradingViewProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [widgetReady, setWidgetReady] = useState(false);
  const [defaultSymbol, setDefaultSymbol] = useLocalStorage(
    "defaultSymbol",
    "BTCUSDT"
  );
  const lastDailyBarRef = useRef(state.lastDailyBar);

  const { parameters } = useParameters();

  useEffect(() => {
    lastDailyBarRef.current = state.lastDailyBar;
  }, [state.lastDailyBar]);

  useEffect(() => {
    if (state.tvWidget) {
      tvWidgetLocal = state.tvWidget;
      setWidgetReady(true);
      tvWidgetLocal.subscribe("study", (event) => {
        console.log(`A ${event.value} indicator was added`);
      });
    }
  }, [state.tvWidget]);

  useEffect(() => {
    const symbol = defaultSymbol || "BTCUSDT";
    updateSymbol(symbol);

    const checkChartAnalysis = async () => {
      const storedData = localStorage.getItem("currentChartAnalysis");
      const parsedData = storedData ? JSON.parse(storedData) : {};
      const { symbol } = parsedData;

      if (symbol) {
        console.log("symbol", symbol);
        const selectedSymbol = symbol.replace("/", "");
        if (defaultSymbol !== selectedSymbol) {
          updateSymbol(selectedSymbol);
          window.location.reload();
        }
      } else {
        console.log("No symbol found in currentChartAnalysis");
      }
    };

    checkChartAnalysis();
  }, [defaultSymbol]);

  const setWidget = (tvWidget: IChartingLibraryWidget) => {
    dispatch({ type: "SET_TV_WIDGET", payload: tvWidget });
  };

  const setChartOrders = (chartOrders: ChartOrders[]) => {
    dispatch({ type: "SET_CHART_ORDERS", payload: chartOrders });
  };

  const addEventPending = (event: any) => {
    dispatch({ type: "SET_EVENTS_PENDING", payload: event });
  };

  const removeEventPending = () => {
    dispatch({ type: "REMOVE_EVENTS_PENDING", payload: null });
  };

  const setCandleTime = (timestamp: number) => {
    dispatch({ type: "SET_CANDLE_TIME", payload: timestamp });
  };

  const addChartOrders = async (orders: Order[], currentTimeframe: string) => {
    try {
      const time = window.tvWidget?.activeChart() ? 0 : 5000;

      await delay(time);

      const removeOrdersByTimeframe = async () => {
        return new Promise<void>(async (resolve) => {
          const promises = state.orders
            .filter((o) => o.timeframe !== currentTimeframe)
            .map((order) => removeOrderById(order.orderId));
          await Promise.all(promises);
          resolve();
        });
      };

      await removeOrdersByTimeframe();

      const groupedOrders = (direction: string) =>
        orders
          .filter((order) => order.direction.toUpperCase() === direction)
          .reduce((result, order) => {
            const identifier = `${order.candleStartTime}-${direction}`;
            if (!result[identifier]) result[identifier] = [];
            result[identifier].push(order);
            return result;
          }, {});

      const groupedOrdersBull = groupedOrders("BULL");
      const groupedOrdersBear = groupedOrders("BEAR");
      const bands = calculateBollingerBands();

      console.log({
        groupedOrdersBull,
        groupedOrdersBear,
        bands,
      });

      const processPendingOrders = (groupedOrders: any, direction: any) => {
        Object.entries(groupedOrders).forEach(
          ([identifiers, orders]: [string, Order[]]) => {
            if (orders[0].status.toUpperCase() !== "PENDING") return;
            if (orders[0].direction.toUpperCase() !== direction) return;

            const symbol = orders[0].symbol;

            const [candleStartTime] = identifiers.split("-") as [string, any];

            const baseUniqueId = `order-${direction}-${candleStartTime}-PENDING`;

            const isBull = direction === "BULL";

            const pending = true;
            const color = "#faa63b";

            for (const order of orders) {
              const existOrder = state.orders.find(
                (i) => i.orderId === `${baseUniqueId}-${order.id}`
              );

              if (existOrder) {
                continue;
              }

              const price = order.cop || order.price;

              const label = `$ ${order.accept ? order.accept : order.invest}`;

              const newUniqueId = `${baseUniqueId}-${order.id}`;

              const timeframe = order.candleTimeFrame;

              const calloutPoint = calculateCalloutPoint(
                timeframe,
                parseInt(candleStartTime),
                price,
                isBull,
                pending,
                bands
              );
              const orderShape = createCalloutOrder({
                label,
                color,
                calloutPoint,
                timeframe,
                orderId: newUniqueId,
                orders,
                pending,
              });

              addOrder({
                order: orderShape,
                orderId: newUniqueId,
                direction,
                timeframe,
                symbol,
                status: "PENDING",
              });
            }
          }
        );
      };

      const processOpenOrders = (groupedOrders: any, direction: any) => {
        Object.entries(groupedOrders).forEach(
          ([identifiers, orders]: [string, Order[]]) => {
            if (orders[0].status.toUpperCase() !== "OPEN") return;
            if (orders[0].direction.toUpperCase() !== direction) return;

            const [candleStartTime] = identifiers.split("-") as [string, any];

            const baseUniqueId = `order-${direction}-${candleStartTime}`;
            const isBull = direction === "BULL";

            const color = isBull ? "#08C58A" : "#FF025C";
            const orderIndex = orders.length - 1;
            const price = orders[orderIndex].cop || orders[orderIndex].price;
            const timeframe = orders[orderIndex].candleTimeFrame;

            for (const order of orders) {
              const baseUniqueId = `order-${direction}-${candleStartTime}`;
              const orderPendingId = `${baseUniqueId}-PENDING`;

              const ordersToRemove = state.orders.filter((i) =>
                i.orderId.includes(orderPendingId)
              );

              for (const orderToRemove of ordersToRemove) {
                removeOrderById(orderToRemove.orderId);
              }
            }

            const totalOrder = orders.reduce(
              (acc, order) =>
                acc + (order.accept ? order.accept : order.invest),
              0
            );

            const label = `$ ${totalOrder}`;

            let existOrder = state.orders.find(
              (i) => i.orderId === `${baseUniqueId}-OPEN`
            );

            if (existOrder) {
              existOrder.order
                .setText(label)
                .setLineColor(color)
                .setQuantityBorderColor(color)
                .setQuantityBackgroundColor(color)
                .setBodyBorderColor(color)
                .setBodyTextColor(color)
                .setPrice(price);
              updateOrderQuantityWithTimeFrame({
                orderShape: existOrder.order,
                timeframe: existOrder.timeframe,
                fee: parameters.FEE_RATE.value,
                orderId: existOrder.orderId,
                orders,
              });
              return;
            }

            const newUniqueId = `${baseUniqueId}-OPEN`;
            const calloutPoint = calculateCalloutPoint(
              orders[0].candleTimeFrame,
              parseInt(candleStartTime),
              price,
              isBull,
              false,
              bands
            );
            const order = createCalloutOrder({
              label,
              color,
              calloutPoint,
              timeframe,
              orderId: newUniqueId,
              orders,
            });

            addOrder({
              order,
              orderId: newUniqueId,
              direction,
              timeframe: orders[0].candleTimeFrame,
              symbol: orders[0].symbol,
              status: "OPEN",
            });
          }
        );
      };

      processPendingOrders(groupedOrdersBull, "BULL");
      processPendingOrders(groupedOrdersBear, "BEAR");

      processOpenOrders(groupedOrdersBull, "BULL");
      processOpenOrders(groupedOrdersBear, "BEAR");
    } catch (error) {
      console.log("addChartOrders error: ", error);
    }
  };

  const removeOrdersBySymbol = (symbol) => {
    state.orders
      .filter((o) => o.symbol !== symbol)
      .forEach((order) => removeOrderById(order.orderId));
  };

  const createCalloutOrder = ({
    color,
    label,
    calloutPoint,
    timeframe,
    orderId,
    orders,
    pending,
  }: {
    color: string;
    label: string;
    calloutPoint: {
      startTime: number;
      endTime: number;
      priceStart: number;
      priceEnd: number;
    };
    timeframe: string;
    orderId: string;
    orders: Order[];
    pending?: boolean;
  }) => {
    const { priceStart } = calloutPoint;

    const order = window.tvWidget
      .activeChart()
      .createOrderLine()
      .setText(label)
      .setLineLength(6)
      .setLineStyle(3)
      .setQuantity("00:00")
      .setEditable(true)
      .setLineColor(color)
      .setQuantityBorderColor(color)
      .setQuantityBackgroundColor(color)
      .setBodyBorderColor(color)
      .setBodyTextColor(color)
      .setBodyBackgroundColor("rgb(20, 20, 24)")
      .setPrice(priceStart);

    updateOrderQuantityWithTimeFrame({
      orderId,
      orderShape: order,
      orders,
      timeframe,
      fee: parameters.FEE_RATE.value,
      pending,
    });

    return order;
  };

  const updateOrderQuantityWithTimeFrame = ({
    orderId,
    orderShape,
    orders,
    timeframe,
    fee,
    pending,
  }: {
    orderId: string;
    orderShape: any;
    orders: Order[];
    timeframe: string;
    fee: number;
    pending?: boolean;
  }) => {
    if (orderIntervals.has(orderId) && !pending) {
      clearInterval(orderIntervals.get(orderId));
      orderIntervals.delete(orderId);
    }

    let remainingTime = calculateDurationFromTimeFrame(timeframe) * 1000;
    console.log("remainingTime", remainingTime);
    const intervalId = setInterval(() => {
      let totalResult = "";
      let colorResult = "#ffffff";
      let simbolResult = "";
      const minutes = Math.floor(remainingTime / 60000);
      const seconds = ((remainingTime % 60000) / 1000).toFixed(0);
      const timeString = `${minutes}:${seconds.padStart(2, "0")}`;

      orderShape.setQuantity(timeString);

      if (!pending) {
        const totalOrder = orders.reduce((acc, order) => {
          const { color, simbol, value } = getResulteOnLive({
            order,
            fee,
            lastDailyBar: lastDailyBarRef.current,
          });
          colorResult = color;
          simbolResult = simbol;

          return acc + Number(value);
        }, 0);

        const label = `${simbolResult}$${parseFloat(totalOrder.toFixed(2))}`;

        orderShape.setText(label).setBodyTextColor(colorResult);
      }

      remainingTime -= 1000;
      if (remainingTime <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    orderIntervals.set(orderId, intervalId);
  };

  const calculateDurationFromTimeFrame = (timeFrame: string) => {
    if (!timeFrame) return 0;

    const dataBroker = new Date(getBrokerNowTime());
    let resultTimeCalculate = 0;
    let timer = 0;
    let minutes = dataBroker.getMinutes();
    let seconds = dataBroker.getSeconds();

    console.log("dataBroker", dataBroker);
    console.log("timeFrame", timeFrame);

    switch (timeFrame) {
      case "M1":
        resultTimeCalculate = 1 * 60 - seconds;
        break;
      case "M5":
        do {
          timer += 5;
        } while (!(minutes < timer));
        resultTimeCalculate = (timer - minutes) * 60 - seconds;
        break;
      case "M15":
        do {
          timer += 15;
        } while (!(minutes < timer));
        resultTimeCalculate = (timer - minutes) * 60 - seconds;
        break;
      default:
        break;
    }

    return resultTimeCalculate;
  };

  const updateSymbol = (symbol: string) => {
    setDefaultSymbol(symbol);
    dispatch({ type: "SET_SELECTED_SYMBOL", payload: symbol });
  };

  const addOrder = (orderItem: OrderItem) => {
    dispatch({ type: "ADD_ORDER", payload: orderItem });
  };

  const removeOrderById = (orderId: string) => {
    dispatch({ type: "REMOVE_ORDER_BY_ID", payload: orderId });
  };

  const closeOrderById = (orderId: string) => {
    dispatch({ type: "REMOVE_ORDER_BY_ID", payload: orderId });
  };

  const closeAllOrders = () => {
    dispatch({ type: "SET_CHART_ORDERS", payload: [] });
  };

  const winLoseChartOrder = async (order: WinLoseOrderType, status: string) => {
    const winStatus = status === OrderStatusEnum.WIN;
    const backgroundColor = winStatus ? "#08C58A" : "#FF025C";
    const value = formatCurrency(order.value);
    const formattedValue = winStatus ? `+U${value}` : `-U${value}`;
    const text = `Resultado \n${formattedValue} `;

    if (order.symbol !== defaultSymbol) return;

    // Obter o valor de fechamento do candle atual
    const lastDailyBar = lastDailyBarRef.current;

    if (!lastDailyBar) {
      console.error("Não foi possível obter o valor de fechamento do candle.");
      return;
    }

    const closePrice = lastDailyBar.close;
    const closeTime = lastDailyBar.time;

    const activeChart = tvWidgetLocal.activeChart();
    const calloutId = activeChart.createMultipointShape(
      [
        { time: closeTime, price: closePrice },
        { time: closeTime, price: closePrice },
      ],
      {
        shape: "callout",
        text,
        lock: true,
        disableSelection: true,
        disableUndo: true,
        disableSave: true,
        overrides: {
          bold: true,
          fontsize: window.innerWidth <= 768 ? 12 : 14,
          textAlign: "center",
          backgroundColor,
          borderColor: backgroundColor,
          drawBorder: true,
          linewidth: 1,
          transparency: 0,
        },
        zOrder: "top",
      }
    );

    window.requestAnimationFrame(() => console.log("Gráfico atualizado!"));

    setTimeout(() => {
      activeChart.removeEntity(calloutId);
    }, 3500);
  };

  const removeChartOrders = (groupedOrders: GroupedOrders[]) => {
    const { chartOrders } = state;

    try {
      if (groupedOrders.length > 0) {
        let _chartOrders = chartOrders;
        for (const orderToRemove of groupedOrders) {
          _chartOrders = _chartOrders.filter((o) => o.id !== orderToRemove.id);

          const chartOrder = chartOrders.find(
            (chartOrder) => chartOrder.id === orderToRemove.id
          );

          if (chartOrder) {
            tvWidgetLocal.activeChart().removeEntity(chartOrder.entities[0]);
          }
        }

        setChartOrders(_chartOrders);
      }
    } catch (error) {
      console.log("removeChartOrders error => ", error);
    }
  };

  const removeAllCallouts = () => {
    const allCallouts = tvWidgetLocal
      .activeChart()
      .getAllShapes()
      .filter((item) => item.name === "callout");

    allCallouts.forEach((item) => {
      tvWidgetLocal.activeChart().removeEntity(item.id);
    });
  };

  const updateLastDialyBar = (lastDailyBar: LastDailyBar) => {
    dispatch({ type: "UPDATE_LAST_DAILY_BAR", payload: lastDailyBar });
  };

  const calculateNewValue = (
    value: number,
    bull: boolean,
    bb?: BollingerBands,
    timeFrame?: string
  ) => {
    let newValue = value;
    let offset = 0;

    if (timeFrame && bb) {
      offset = bb.maxHeight;
    }
    newValue = bull ? newValue + offset : newValue - offset;

    return newValue;
  };

  const calculateBollingerBands = (): BollingerBands => {
    const period = 20;
    const factor = 2;

    const { _items } = tvWidgetLocal
      .activeChart()
      .getSeries() //@ts-ignore
      .data()
      .bars();

    const candles = _items;
    const prices = candles.slice(-period).map((candle) => candle.value[4]);
    const sma = prices.reduce((sum, price) => sum + price, 0) / period;
    const stdDev = Math.sqrt(
      prices.reduce((sum, price) => sum + Math.pow(price - sma, 2), 0) / period
    );
    const upperBand = sma + stdDev * factor;
    const lowerBand = sma - stdDev * factor;
    const maxHeight = upperBand - lowerBand;

    return { sma, upperBand, lowerBand, maxHeight };
  };

  const calculateCalloutPoint = (
    timeFrame: string,
    candleStartTime: number,
    price: number,
    isBull: boolean,
    pending: boolean,
    bb: BollingerBands
  ) => {
    const priceStart = price;
    const priceEnd = calculateNewValue(priceStart, isBull, bb, timeFrame);

    const startTime = pending
      ? convertTimeToCandlePoint(candleStartTime, "subMinutes", 1)
      : candleStartTime;

    let minutes = 0;
    switch (timeFrame) {
      case "M1":
        minutes = pending ? 6 : 5;
        break;
      case "M5":
        minutes = pending ? 30 : 25;
        break;
      case "M15":
        minutes = pending ? 90 : 75;
        break;
      default:
        break;
    }
    const endTime = convertTimeToCandlePoint(
      candleStartTime,
      pending ? "subMinutes" : "addMinutes",
      minutes
    );

    return { startTime, endTime, priceStart, priceEnd };
  };

  return (
    <TradingViewContext.Provider
      value={{
        ...state,
        widgetReady,
        setWidget,
        addChartOrders,
        setChartOrders,
        removeChartOrders,
        winLoseChartOrder,
        removeAllCallouts,
        setCandleTime,
        updateLastDialyBar,
        addEventPending,
        removeEventPending,
        updateSymbol,
        closeOrderById,
        closeAllOrders,
        removeOrderById,
        removeOrdersBySymbol,
      }}
    >
      {children}
    </TradingViewContext.Provider>
  );
};

TradingViewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TradingViewConsumer = TradingViewContext.Consumer;

export default TradingViewContext;
