import { Suspense, lazy } from "react";
import type { RouteObject } from "react-router";
import { StompSessionProvider } from "react-stomp-hooks";
import AuthGuard from "./components/AuthGuard";
import CountdownGuard from "./components/CountdownGuard";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import MaintenanceGuard from "./components/MaintenanceGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import { OrderBookProvider } from "./contexts/OrderBookContext";
import DepositPayment from "./pages/dashboard/DepositPayment";
import OverviewTestTradingview from "./pages/dashboard/OverviewTestTradingview";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Browse pages

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const PasswordRecovery = Loadable(
  lazy(() => import("./pages/authentication/PasswordRecovery"))
);
const PasswordReset = Loadable(
  lazy(() => import("./pages/authentication/PasswordReset"))
);
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const VerifyCode = Loadable(
  lazy(() => import("./pages/authentication/VerifyCode"))
);

const WaitingList = Loadable(
  lazy(() => import("./pages/authentication/WaitingList"))
);

// Dashboard pages
const Profile = Loadable(lazy(() => import("./pages/dashboard/Profile")));
const Deposit = Loadable(lazy(() => import("./pages/dashboard/Deposit")));
const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));
const BalanceHistory = Loadable(
  lazy(() => import("./pages/dashboard/BalanceHistory"))
);
const OperationsHistory = Loadable(
  lazy(() => import("./pages/dashboard/OperationsHistory"))
);

// Error pages
const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// Other pages
const Home = Loadable(lazy(() => import("./pages/Home")));

const token = window.localStorage.getItem("accessToken");
let accountId = window.localStorage.getItem("accountId")?.replaceAll('"', "");

const wsUrl = `${process.env.REACT_APP_WEBSOCKET_URL}?authorization=${token}&accountId=${accountId}`;

const routes: RouteObject[] = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      // {
      //   path: "login-unguarded",
      //   element: <Login />,
      // },
      {
        path: "password-recovery",
        element: <PasswordRecovery />,
      },
      {
        path: "password-reset",
        element: <PasswordReset />,
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: "register-unguarded",
        element: <Register />,
      },
      {
        path: "verify-code",
        element: <VerifyCode />,
      },
      {
        path: "waiting_list",
        element: <WaitingList />,
      },
    ],
  },
  {
    path: "traderoom",
    element: (
      <CountdownGuard>
        <StompSessionProvider
          url={wsUrl}
          connectionTimeout={60000}
          heartbeatIncoming={10000}
          heartbeatOutgoing={10000}
          onConnect={() => {}}
          beforeConnect={async () => {}}
          onStompError={(error) => {
            console.log("Socket error: ", error);
          }}
        >
          <MaintenanceGuard>
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          </MaintenanceGuard>
        </StompSessionProvider>
      </CountdownGuard>
    ),
    children: [
      {
        path: "",
        element: (
          <OrderBookProvider>
            <Overview />
          </OrderBookProvider>
        ),
      },
      {
        path: "v2",
        element: (
          <OrderBookProvider>
            <OverviewTestTradingview />
          </OrderBookProvider>
        ),
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <CountdownGuard>
        <MaintenanceGuard>
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        </MaintenanceGuard>
      </CountdownGuard>
    ),
    children: [
      {
        path: "",
        element: <NotFound />,
      },
      // {
      //   path: "account",
      //   element: <Account />,
      // },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "deposit",
        element: <Deposit />,
      },
      {
        path: "deposit/:wallitId",
        element: <DepositPayment />,
      },
      {
        path: "history/balance",
        element: <BalanceHistory />,
      },
      {
        path: "history/operations",
        element: <OperationsHistory />,
      },
    ],
  },
  {
    path: "*",
    children: [
      ...[
        "",
        "login",
        "register",
        "password-recovery",
        "password-recovery/code",
        "registration-success",
        "email-confirmation",
        "2fa",
        "reset-password",
      ].map((page) => ({
        path: page,
        element: (
          <GuestGuard>
            <Home />
          </GuestGuard>
        ),
      })),
      // {
      //   path: "",
      //   element: (
      //     <CountdownGuard>
      //       <GuestGuard>
      //         <Login />
      //       </GuestGuard>
      //     </CountdownGuard>
      //   ),
      // },
      // {
      //   path: "login",
      //   element: (
      //     <CountdownGuard>
      //       <GuestGuard>
      //         <Login />
      //       </GuestGuard>
      //     </CountdownGuard>
      //   ),
      // },
      // {
      //   path: "register",
      //   element: (
      //     <CountdownGuard>
      //       <GuestGuard>
      //         <Register />
      //       </GuestGuard>
      //     </CountdownGuard>
      //   ),
      // },
      // {
      //   path: "confirmation-email",
      //   element: (
      //     <CountdownGuard>
      //       <GuestGuard>
      //         <ConfirmationEmailCode />
      //       </GuestGuard>
      //     </CountdownGuard>
      //   ),
      // },
      // {
      //   path: "confirmation-2f-code",
      //   element: (
      //     <CountdownGuard>
      //       <GuestGuard>
      //         <Confirmation2FCode />
      //       </GuestGuard>
      //     </CountdownGuard>
      //   ),
      // },
      // {
      //   path: "verify-code",
      //   element: (
      //     <CountdownGuard>
      //       <GuestGuard>
      //         <VerifyCode />
      //       </GuestGuard>
      //     </CountdownGuard>
      //   ),
      // },
      // {
      //   path: "password-recovery",
      //   element: (
      //     <CountdownGuard>
      //       <PasswordRecovery />
      //     </CountdownGuard>
      //   ),
      // },
      // {
      //   path: "password-reset",
      //   element: (
      //     <CountdownGuard>
      //       <PasswordReset />
      //     </CountdownGuard>
      //   ),
      // },
      // {
      //   path: "change-password",
      //   element: (
      //     <CountdownGuard>
      //       <ChangePassword />
      //     </CountdownGuard>
      //   ),
      // },

      // {
      //   path: "about_us",
      //   element: <AboutUs />,
      // },
      {
        path: "401",
        element: (
          <CountdownGuard>
            <AuthorizationRequired />
          </CountdownGuard>
        ),
      },
      {
        path: "404",
        element: (
          <CountdownGuard>
            <NotFound />
          </CountdownGuard>
        ),
      },
      {
        path: "500",
        element: (
          <CountdownGuard>
            <ServerError />
          </CountdownGuard>
        ),
      },
      {
        path: "*",
        element: (
          <CountdownGuard>
            <NotFound />
          </CountdownGuard>
        ),
      },
    ],
  },
];

export default routes;
