import "react-perfect-scrollbar/dist/css/styles.css";

import "animate.css/animate.min.css";
import "nprogress/nprogress.css";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/index.css";
// import './__mocks__';
import { StyledEngineProvider } from "@mui/material/styles";
import React, { StrictMode } from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./contexts/JWTContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { TradingViewProvider } from "./contexts/TradingViewContext";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
  apiKey: "6052a1f260044bc98a6fa7e73ea20d1d",
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <StrictMode>
      <HelmetProvider>
        <CookiesProvider>
          <StyledEngineProvider injectFirst>
            <SettingsProvider>
              <LayoutProvider>
                <BrowserRouter>
                  <AuthProvider>
                    <TradingViewProvider>
                      <App />
                    </TradingViewProvider>
                  </AuthProvider>
                </BrowserRouter>
              </LayoutProvider>
            </SettingsProvider>
          </StyledEngineProvider>
        </CookiesProvider>
      </HelmetProvider>
    </StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
