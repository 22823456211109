import type { FC } from 'react';
import { SxProps, Theme, styled } from '@mui/material/styles';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = styled('img')();

const Logo: FC<LogoProps> = (props) => (
  <LogoRoot
    src="/static/logo_new.png"
    height="52"
    {...props}
  />
);

export default Logo;
