import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import type { FC } from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SettingsContext from "src/contexts/SettingsContext";

const languageOptions = {
  br: {
    icon: "/static/icons/pt_flag.png",
    label: "Português",
  },
  en: {
    icon: "/static/icons/uk_flag.svg",
    label: "English",
  },
  // de: {
  //   icon: '/static/icons/de_flag.svg',
  //   label: 'German'
  // },
  es: {
    icon: "/static/icons/es_flag.svg",
    label: "Español",
  },
};

const LanguagePopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { i18n } = useTranslation();
  const { settings, saveSettings } = useContext(SettingsContext);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    i18n.changeLanguage(settings.language);
  }, []);

  const handleChangeLanguage = (language: string): void => {
    i18n.changeLanguage(language);
    saveSettings({
      ...settings,
      language,
    });
    setOpen(false);
  };

  const selectedOption = languageOptions[i18n.language];

  return (
    <>
      <Box sx={{ alignSelf: "center", mr: 2 }}>
        <IconButton sx={{ padding: 0 }} onClick={handleOpen} ref={anchorRef}>
          <Box
            sx={{
              display: "flex",
              height: 20,
              width: 20,
              "& img": {
                width: "100%",
                height: "auto",
              },
            }}
          >
            <img alt={selectedOption.label} src={selectedOption.icon} />
          </Box>
        </IconButton>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            onClick={() => handleChangeLanguage(language)}
            key={language}
          >
            <ListItemIcon>
              <Box
                sx={{
                  display: "flex",
                  height: 20,
                  width: 20,
                  "& img": {
                    width: "100%",
                  },
                }}
              >
                <img
                  alt={languageOptions[language].label}
                  src={languageOptions[language].icon}
                />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {languageOptions[language].label}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
