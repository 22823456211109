import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { Box, IconButton } from "@mui/material";
import { useContext } from "react";
import SettingsContext from "src/contexts/SettingsContext";

export default function SoundButton() {
  const { settings, saveSettings } = useContext(SettingsContext);

  const handleToggleSound = (): void => {
    saveSettings({
      ...settings,
      isSoundOn: !settings.isSoundOn,
    });
  };

  return (
    <Box sx={{ alignSelf: "center", mr: 2 }}>
      <IconButton sx={{ padding: 0 }} onClick={handleToggleSound}>
        <Box
          sx={{
            display: "flex",
            height: 20,
            width: 20,
            "& img": {
              width: "100%",
              height: "auto",
            },
          }}
        >
          {settings.isSoundOn ? (
            <VolumeDownIcon />
          ) : (
            <VolumeOffIcon
              style={{
                color: "#3a3a3a",
              }}
            />
          )}
        </Box>
      </IconButton>
    </Box>
  );
}
