import { useContext, useEffect, useMemo } from "react";
import LayoutContext from "src/contexts/LayoutContext";
import TradingViewContext from "src/contexts/TradingViewContext";
import { apiGet } from "src/services/apiService";
import useSWR, { mutate } from "swr";

export default function useAvailableSymbols() {
  const { data, isValidating } = useSWR<any>(
    "/orders/availableSymbols",
    apiGet,
    {
      dedupingInterval: 10000,
    }
  );

  const { setModalOutsideMarket } = useContext(LayoutContext);
  const { selectedSymbol } = useContext(TradingViewContext);
  const symbols = useMemo(() => {
    if (data) {
      return data;
    }
    return [];
  }, [data]);

  useEffect(() => {
    const activeSymbol = symbols?.find(
      (item) => item.symbol === selectedSymbol
    );

    if (activeSymbol && activeSymbol.marketStatus !== "OPEN") {
      setModalOutsideMarket(
        true,
        `O gráfico do índice ${activeSymbol.symbol} está fechado no momento.`
      );
    }
  }, [symbols]);

  return {
    symbols: data || [],
    loading: !data,
    isValidating,
    mutate,
  };
}
