import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  Modal,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import LayoutContext from "src/contexts/LayoutContext";
import { Close, Mail, Telegram } from "@mui/icons-material";
import Logo from "../Logo";
import useAuth from "src/hooks/useAuth";
import { useLocalStorage } from "usehooks-ts";
import useApiData from "src/hooks/useApiData";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 550,
      border: "1px rgba(255, 255, 255, 0.2) solid",
    },
    chip: {
      width: 18,
      height: 18,
      backgroundColor: "rgba(145, 158, 171, 0.24)",
      borderRadius: 0.51,
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  })
);

function OutsideMarketModal() {
  const { t } = useTranslation("dashboard");
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { layout, setModalOutsideMarket } = useContext(LayoutContext);
  const handleCloseModalReal = () => {
    setModalOutsideMarket(false, "");
  };

  const [defaultSymbol, setDefaultSymbol] = useLocalStorage(
    "defaultSymbol",
    "BTCUSDT"
  );

  const handleCloseOutsideMarketModal = async () => {
    const activeSymbol = localStorage.getItem("activeSymbol");
    if (activeSymbol !== "BTCUSDT") {
      if (layout.relaodPage) {
        await setDefaultSymbol("BTCUSDT");
        window.location.href = "/traderoom";
      }
      handleCloseModalReal();
    } else {
    }
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={layout.modalOutsideMarket}
      onClose={() => handleCloseModalReal()}
    >
      <Paper
        sx={{ p: 3, minWidth: isMobile ? "80%" : null }}
        className={classes.paper}
      >
        <IconButton
          aria-label="close"
          onClick={() => handleCloseModalReal()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <Stack direction="row" spacing={2}>
          <Stack direction={"column"} spacing={2} py={5}>
            <Typography
              variant="subtitle1"
              fontWeight={300}
              textAlign={"center"}
            >
              {layout.outsideMarketText}
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={(e: any) => handleCloseOutsideMarketModal()}
          >
            Ok
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}

export default OutsideMarketModal;
