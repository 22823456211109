import { Box, Chip, IconButton, Modal, Paper, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { format } from "date-fns";
import translate from "src/utils/translatorUtil";
import labelsColors from "src/theme/labelsColors";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { Close } from "@mui/icons-material";

interface DetailItemHistoryProps {
  item: string[];
  columns: string[];
  open: boolean;
  handleClose: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 650,
  width: "80%",
  p: 4,
  border: "1px rgba(255, 255, 255, 0.2) solid",
};

const removeColumns = [
  "price",
  "partnerLink",
  "referralComission",
  "createdAtBrokerTime",
];
function DetailItemHistory({
  open,
  item,
  columns,
  handleClose,
}: DetailItemHistoryProps) {
  const { t } = useTranslation("dashboard");
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
    >
      <Paper sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {item
              .filter((item) => removeColumns.includes(item[0]) !== true)
              .map((value, index) => (
                <Grid xs={6}>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{
                      fontSize: 12,
                      mt: 2,
                    }}
                  >
                    {t(
                      columns[index] === "candle_time"
                        ? "symbol"
                        : columns[index] === "symbol"
                        ? "candle_time"
                        : columns[index]
                    )}
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{
                      fontSize: 14,
                      color: "#919eab",
                    }}
                  >
                    {value[0] === "date" || value[0] === "candleStartTime" ? (
                      <>{format(new Date(value[1]), "HH:mm")}</>
                    ) : value[0] === "amount" ? (
                      <> {numeral(value[1]).format("0,0.00")}</>
                    ) : value[0] === "transactionType" ? (
                      <>{t(value[1].toLowerCase())}</>
                    ) : value[0] === "investment" ? (
                      <> {numeral(value[1]).format("0,0.00")}</>
                    ) : value[0] === "status" ? (
                      <>
                        <Chip
                          label={t(translate(value[1]))}
                          sx={{
                            ...labelsColors[value[1]],
                            fontWeight: 400,
                            fontSize: 14,
                          }}
                        />
                      </>
                    ) : value[0] === "orderDirection" ||
                      value[0] === "direction" ? (
                      <>
                        <Chip
                          label={t(translate(value[1]))}
                          sx={{
                            ...labelsColors[value[1]],
                            fontWeight: 400,
                            fontSize: 14,
                          }}
                        />
                      </>
                    ) : (
                      value[1]
                    )}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
}

DetailItemHistory.propTypes = {
  open: PropTypes.bool,
  item: PropTypes.array,
  columns: PropTypes.array,
  handleClose: PropTypes.func,
};

export default DetailItemHistory;
