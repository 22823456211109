import {
  Box,
  Chip,
  CircularProgress,
  Paper,
  Skeleton,
  // IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { orderBy } from "lodash";
import numeral from "numeral";
import { FC, memo, useCallback, useContext, useEffect, useState } from "react";
import Scrollbar from "../../Scrollbar";
// import TrashIcon from '../../../icons/Trash';
import { useTranslation } from "react-i18next";
import { getBrokerNowTime } from "src/components/TVChartContainer/datafeed";
import { getLastBar } from "src/components/TVChartContainer/streaming";
import DetailItemHistory from "src/components/modals/DetailItemHistory";
import LayoutContext from "src/contexts/LayoutContext";
import TradingViewContext from "src/contexts/TradingViewContext";
import useParameters from "src/swr/use-parameters";
import labelsColors from "src/theme/labelsColors";
import { getResulteOnLive } from "src/utils/getResultOnLine";
import useApiData from "../../../hooks/useApiData";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { apiGet } from "../../../services/apiService";
import { Order, OrderDirectionEnum } from "../../../types/order";
import translate from "../../../utils/translatorUtil";

const labelColors = {
  complete: "success",
  pending: "warning",
  rejected: "error",
  // orders
  OPEN: "info",
  PENDING: "warning",
  REFUNDED: "error",
  WIN: "success",
  LOSE: "error",
};

let Columns = [
  "order_id",
  "active",
  "candle",
  "time",
  "candle_time",
  "prevision",
  "value",
  "fees",
  "reversed",
  "executed",
  "status",
  "result",
];

interface TableLiveOperationsProps {
  selectedTab: number;
  statuses: string[];
  page?: string;
  isSimplified: boolean;
}

const Row = memo<{
  row: any;
  isMobile: boolean;
}>((props) => {
  const { t } = useTranslation("dashboard");
  const { row, isMobile } = props;

  const { parameters, loading } = useParameters();
  const { lastDailyBar } = useContext(TradingViewContext);

  const resultOnLive = (order: any) => {
    return getResulteOnLive({
      order,
      fee: parameters.FEE_RATE.value,
      lastDailyBar,
    });
  };

  return (
    <TableRow
      hover
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
        ".css-8boiwb-MuiTableCell-root.MuiTableCell-paddingCheckbox": {
          padding: isMobile && "0 0 0 10px !important",
        },
      }}
    >
      <TableCell align="center" size="small">
        <Typography
          color="textPrimary"
          variant="subtitle2"
          sx={{
            fontSize: 12,
          }}
        >
          {row.symbol}
        </Typography>
      </TableCell>
      <TableCell align="center" size="small">
        <Typography
          color="textPrimary"
          variant="subtitle2"
          sx={{
            fontSize: 12,
          }}
        >
          {format(new Date(row.candleStartTime), "HH:mm")}
        </Typography>
      </TableCell>
      <TableCell align="center" size="small">
        <Typography
          color="textPrimary"
          variant="subtitle2"
          sx={{
            fontSize: 12,
          }}
        >
          {row.candleTimeFrame}
        </Typography>
      </TableCell>
      <TableCell align="center" size="small">
        <Typography
          color="textPrimary"
          variant="subtitle2"
          sx={{
            fontSize: 12,
          }}
        >
          {row.direction === OrderDirectionEnum.BULL.toUpperCase() ? (
            <Chip
              size="small"
              label={t("bull")}
              sx={{
                my: 0.5,
                fontWeight: 500,
                fontSize: 12,
                color: "#00DB97",
                backgroundColor: "#00DB4A08",
              }}
            />
          ) : (
            <Chip
              size="small"
              label={t("bear")}
              sx={{
                my: 0.5,
                fontWeight: 500,
                fontSize: 12,
                color: "#FE025C",
                backgroundColor: "#FE025C08",
              }}
            />
          )}
        </Typography>
      </TableCell>
      {!isMobile && (
        <>
          <TableCell align="center" size="small">
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{
                fontSize: 12,
              }}
            >
              {numeral(row.invest).format("0,0.00")}
            </Typography>
          </TableCell>
          <TableCell align="center" size="small">
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{
                fontSize: 12,
              }}
            >
              {numeral(row.refund).format("0,0.00")}
            </Typography>
          </TableCell>
          <TableCell align="center" size="small">
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{
                fontSize: 12,
              }}
            >
              {" "}
              {numeral(row.accept).format("0,0.00")}
            </Typography>
          </TableCell>
          <TableCell align="center" size="small">
            <Chip
              label={t(translate(row.status.toString()))}
              sx={{
                ...labelsColors[row.status],
                fontWeight: 500,
                fontSize: 12,
              }}
            />
          </TableCell>{" "}
        </>
      )}
      <TableCell align="center" size="small">
        {loading ? (
          <Skeleton />
        ) : (
          <Typography
            color="textPrimary"
            variant="subtitle2"
            sx={{
              fontSize: 12,
              color: resultOnLive(row).color,
            }}
          >
            {`${resultOnLive(row).simbol}${resultOnLive(row).result}`}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
});

const TableLiveOperations: FC<TableLiveOperationsProps> = (props) => {
  const { t } = useTranslation("dashboard");
  const isMountedRef = useIsMountedRef();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { layout } = useContext(LayoutContext);
  const { lastDailyBar, selectedSymbol } = useContext(TradingViewContext);
  const { userLiveOperations, updateUserLiveOperatons } = useApiData();
  const { parameters } = useParameters();

  // eslint-disable-next-line react/prop-types
  const { selectedTab, statuses, page, isSimplified } = props;

  const [itemDetail, setItemDetail] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activePrice, setActivePrice] = useState(0);
  const [ordersLive, setOrdersLive] = useState<any>([]);
  const [dateBroker, setDateBroker] = useState(0);

  const lastBar = getLastBar(selectedSymbol);

  // removing columns for simplified version
  if (isSimplified) {
    Columns = Columns.filter(
      (it) =>
        it !== "order_id" && it !== "time" && it !== "fees" && it !== "action"
    );
  }

  const columns = Columns.filter((c) => (isMobile ? ![].includes(c) : c));

  const fetchOrders = async () => {
    if (!parameters) {
      return;
    }

    setLoading(true);
    try {
      const candleTimeFrames = JSON.parse(parameters.CANDLE_TIME_FRAMES.value);
      const timeFrames = candleTimeFrames.map((item) => item.value).join(",");

      const response = await apiGet<Order[]>(
        statuses.length === 0
          ? `/orders?candleTimeFrames=${timeFrames}&symbols=${selectedSymbol}&page=0&size=10`
          : `/orders?candleTimeFrames=${timeFrames}&symbols=${selectedSymbol}&statuses=${String(
              statuses
            )}&page=0&size=10`
      );

      const ordersOrdered = orderBy(
        response,
        [(item) => new Date(item.createdAt)],
        ["asc"]
      );

      const orders = ordersOrdered.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});

      console.log("orders", orders);
      console.log("ordersOrdered", ordersOrdered);

      await updateUserLiveOperatons(ordersOrdered, false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const getOrders = useCallback(async () => {
    await fetchOrders();
  }, [isMountedRef, parameters, selectedSymbol]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setDateBroker(getBrokerNowTime());
    }, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    setActivePrice(lastBar.close);
  }, [lastBar?.time]);

  const closeModalItemDetail = () => {
    setOpenModalDetail(false);
    setItemDetail([]);
  };

  const getCandleTimeFrameLabel = (orders: any) => {
    const _candleTimeFrames = orders.map((item) => item.candleTimeFrame);
    const _candleTimeFramesUnique = [...new Set(_candleTimeFrames)];

    if (_candleTimeFramesUnique.length === 1) {
      return _candleTimeFramesUnique[0];
    } else {
      const limitedCandleTimeFrames = _candleTimeFramesUnique.slice(0, 2);
      const formattedCandleTimeFrames = limitedCandleTimeFrames.join(", ");
      if (_candleTimeFramesUnique.length > 2) {
        return `${formattedCandleTimeFrames}, ...`;
      } else {
        return formattedCandleTimeFrames;
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: "background.primary",
        fontSize: "80%",
        maxHeight: "225px !important",
      }}
    >
      <Scrollbar heigth={225}>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "225px !important" }}
        >
          <Table
            padding={isMobile ? "checkbox" : "normal"}
            size={`${isMobile ? "small" : null}`}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "0.71875rem",
                    py: isMobile ? "8px !important" : 2,
                    width: "11.11%",
                  }}
                >
                  {t("active")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "0.71875rem",
                    py: isMobile ? "8px !important" : 2,
                    width: "11.11%",
                  }}
                >
                  {t("candle")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "0.71875rem",
                    py: isMobile ? "8px !important" : 2,
                    width: "11.11%",
                  }}
                >
                  {t("candle_time")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "0.71875rem",
                    py: isMobile ? "8px !important" : 2,
                    width: "11.11%",
                  }}
                >
                  {t("prevision")}
                </TableCell>
                {!isMobile && (
                  <>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "0.71875rem",
                        py: isMobile ? "8px !important" : 2,
                        width: "11.11%",
                      }}
                    >
                      {t("value")}
                    </TableCell>
                    {!isSimplified && (
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: "0.71875rem",
                          py: isMobile ? "8px !important" : 2,
                          width: "11.11%",
                        }}
                      >
                        {t("fees")}
                      </TableCell>
                    )}
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "0.71875rem",
                        py: isMobile ? "8px !important" : 2,
                        width: "11.11%",
                      }}
                    >
                      {t("reversed")}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "0.71875rem",
                        py: isMobile ? "8px !important" : 2,
                        width: "11.11%",
                      }}
                    >
                      {t("executed")}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "0.71875rem",
                        py: isMobile ? "8px !important" : 2,
                        width: "11.11%",
                      }}
                    >
                      {t("status")}
                    </TableCell>
                  </>
                )}
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "0.71875rem",
                    py: isMobile ? "8px !important" : 2,
                    width: "11.11%",
                  }}
                >
                  {t("result")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(userLiveOperations).map((order, index) => (
                <Row key={index} isMobile={isMobile} row={order} />
              ))}
            </TableBody>
          </Table>
          {loading && (
            <Box sx={{ textAlign: "center", p: 2 }}>
              <CircularProgress sx={{ width: 25, height: 25 }} size="small" />
            </Box>
          )}
        </TableContainer>
      </Scrollbar>
      <DetailItemHistory
        item={itemDetail}
        open={openModalDetail}
        columns={Columns}
        handleClose={closeModalItemDetail}
      />
    </Box>
  );
};
// TableLiveOperations.propTypes = {
//   statuses: PropTypes.string.isRequired,
//   page: PropTypes.string,
//   selectedTab: number;
//   statuses: string[];
//   page?: string;
//   isSimplified: boolean;
// };

export default memo(TableLiveOperations);
