import labelsColors from "src/theme/labelsColors";
import { OrderDirectionEnum } from "src/types/order";
import formatCurrency from "./formatCurrency";

export const getResulteOnLive = ({
  order,
  fee,
  lastDailyBar,
}: {
  order: any;
  fee: number;
  lastDailyBar: any;
}) => {
  const accept = order.accept;

  if (accept > 0) {
    if (order.cop === lastDailyBar.close) {
      return {
        color: labelsColors["OPEN"].color,
        simbol: "",
        result: formatCurrency(0),
        value: 0,
      };
    }

    const amount = order.accept;
    let estimateIncome = amount * 2 - amount * (fee / 100);
    estimateIncome = parseFloat(estimateIncome.toFixed(3));

    const numericResult = parseFloat((estimateIncome - amount).toFixed(2));
    const formattedResult = formatCurrency(numericResult);

    if (order.direction === OrderDirectionEnum.BULL.toUpperCase()) {
      return order.cop < lastDailyBar.close
        ? {
            color: labelsColors["WIN"].color,
            simbol: "+",
            result: formattedResult,
            value: numericResult,
          }
        : {
            color: labelsColors["LOSE"].color,
            simbol: "-",
            result: formatCurrency(order.accept ?? 0),
            value: order.accept ?? 0,
          };
    } else {
      return order.cop > lastDailyBar.close
        ? {
            color: labelsColors["WIN"].color,
            simbol: "+",
            result: formattedResult,
            value: numericResult,
          }
        : {
            color: labelsColors["LOSE"].color,
            simbol: "-",
            result: formatCurrency(order.accept ?? 0),
            value: order.accept ?? 0,
          };
    }
  } else {
    return {
      color: labelsColors["PENDING"].color,
      simbol: "",
      result: formatCurrency(order.accept ?? 0),
      value: order.accept ?? 0,
    };
  }
};
