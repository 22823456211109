import React, { FC, useContext, useEffect, useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  AppBar,
  Badge,
  useMediaQuery,
  Button,
  Stack,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import PropTypes from "prop-types";
import TableOrderHistory from "../widgets/tables/TableOrderHistory";
import ChevronRightIcon from "../../icons/ChevronRight";
import { useTranslation } from "react-i18next";
import LayoutContext from "src/contexts/LayoutContext";
import TableLiveOperations from "../widgets/tables/TableLiveOperations";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ padding: 0 }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    mt: 0,
  },
}));

const OrderTabsArr = [
  {
    id: 1,
    label: "operations",
    statuses: ["PENDING", "OPEN"],
  },
  {
    id: 2,
    label: "historic",
    statuses: ["WIN", "LOSE", "REFUNDED", "CANCELED"],
  },
  // {
  //   status: 'REFUNDED',
  //   label: 'Estornadas',
  // },
  // {
  //   status: 'CANCELED',
  //   label: 'Canceladas',
  // },
];

interface OrderHistoryProps {
  page?: string;
  isSimplified: boolean;
}

const OrderHistory: FC<OrderHistoryProps> = (props) => {
  const { t } = useTranslation("dashboard");
  const { layout, setOrderHistoryTab } = useContext(LayoutContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();
  // eslint-disable-next-line react/prop-types
  const { page, isSimplified } = props;
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const newTab = OrderTabsArr[newValue].id;
    setOrderHistoryTab(newValue);
  };

  const OrderTabs = OrderTabsArr.map((item, index) => (
    <Tab
      sx={{
        py: isMobile ? 0 : undefined,
        minHeight: isMobile ? 40 : undefined,
      }}
      label={
        <Badge color="primary">
          <div
            style={{
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
            }}
          >
            {t(item.label)}
          </div>
        </Badge>
      }
      key={item.label}
      {...a11yProps(index)}
    />
  ));

  const OrderTabContents = OrderTabsArr.map((item, index) => (
    <TabPanel key={item.id} value={layout.orderHistoryTab} index={index}>
      {layout.orderHistoryTab === 0 && (
        <TableLiveOperations
          page={page}
          selectedTab={item.id}
          statuses={item.statuses}
          isSimplified={isSimplified}
        />
      )}
      {layout.orderHistoryTab === 1 && (
        <TableOrderHistory
          page={page}
          selectedTab={item.id}
          statuses={item.statuses}
          isSimplified={isSimplified}
        />
      )}
    </TabPanel>
  ));

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent">
        <Stack direction={"row"} justifyContent="space-between">
          <Tabs
            value={layout.orderHistoryTab}
            onChange={handleChange}
            aria-label="Order History"
            sx={{ minHeight: isMobile ? 40 : undefined }}
          >
            {OrderTabs}
          </Tabs>
          {page && (
            <Button
              color="primary"
              endIcon={<ChevronRightIcon fontSize="small" />}
              size="small"
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-end",
                color: "#00A667",
              }}
              variant="text"
              component="a"
              href="dashboard/history/operations"
            >
              {isMobile ? t("view_history") : t("view_full_history")}
            </Button>
          )}
        </Stack>
      </AppBar>
      {OrderTabContents}
    </div>
  );
};

OrderHistory.propTypes = {
  page: PropTypes.string,
};

export default OrderHistory;
