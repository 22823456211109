import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC, useContext, useState } from "react";
import useApiData from "../../hooks/useApiData";
import { OrderBookItem } from "../../types/order";
import OrderBookSwitch from "./OrderBookSwitch";
import OrderBookTable from "./OrderBookTable";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled, useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import TradingViewContext from "src/contexts/TradingViewContext";
import useOrderBook from "src/hooks/useOrderBook";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#00000090" : "#00000090",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.mode === "dark" ? "#00000080" : "#00000080",
}));

const OrderBook: FC = () => {
  const { t } = useTranslation("dashboard");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { bookRed, bookGreen } = useOrderBook();
  const { selectedCandle } = useApiData();
  const { selectedSymbol } = useContext(TradingViewContext);
  const [visibleOrderBook, setVisibleOrderBook] = useState(
    isMobile ? true : false
  );

  const temp1: OrderBookItem[] =
    bookGreen && bookGreen.length > 0
      ? bookGreen.filter((it) => it.candleTimeFrame === selectedCandle)[0]
          ?.orderBook || []
      : [];
  const temp2: OrderBookItem[] =
    bookRed && bookRed.length > 0
      ? bookRed.filter((it) => it.candleTimeFrame === selectedCandle)[0]
          ?.orderBook || []
      : [];

  return (
    <Box
      sx={{
        mt: isMobile ? 0 : 3,
        borderRadius: 2,
        bgcolor: "#00000080",
      }}
    >
      <Stack direction={"column"}>
        <Box sx={{ p: 2 }}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight={500} color="white" fontSize={14}>
              {t("order_book")}
            </Typography>
            <IconButton
              color="primary"
              sx={{ color: "white" }}
              aria-label="SyncAlt"
              onClick={() => setVisibleOrderBook(!visibleOrderBook)}
            >
              {visibleOrderBook ? (
                <ExpandLessIcon height={20} />
              ) : (
                <ExpandMoreIcon height={20} />
              )}
            </IconButton>
          </Stack>
        </Box>
        <Divider />
        {visibleOrderBook && (
          <Box sx={{ heigth: "100%" }}>
            <OrderBookTable orders={temp1} color="#08C58A" rowColor="#002900" />
            <OrderBookSwitch />
            <OrderBookTable orders={temp2} color="#FF025C" rowColor="#2F141B" />
          </Box>
        )}
        {!visibleOrderBook && <OrderBookSwitch />}
      </Stack>
    </Box>
  );
};

export default OrderBook;
