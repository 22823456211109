import React, { useContext, useEffect, useState } from "react";
// import { makeStyles, Theme, createStyles } from '@mui/styles';
import {
  Box,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
// import useAuth from '../../../hooks/useAuth';
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ResolutionString } from "src/charting_library/charting_library";
import Countdown from "src/components/widgets/inputs/Countdown";
import CountdonwContext from "src/contexts/CountdownContext";
import LayoutContext from "src/contexts/LayoutContext";
import TradingViewContext from "src/contexts/TradingViewContext";
import useParameters from "src/swr/use-parameters";
import useApiData from "../../../hooks/useApiData";

export default function TimeframeSelect() {
  const { t } = useTranslation("dashboard");
  const { setOrderHistoryTab } = useContext(LayoutContext);
  const { count, setSelectedTimeFrame } = useContext(CountdonwContext);
  const [candleBuckets, setCandleBuckets] = useState(null);
  const [activeMinute, setActiveMinute] = useState(null);
  const { parameters } = useParameters();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { tvWidget, selectedSymbol, removeAllCallouts } =
    React.useContext(TradingViewContext);
  const { selectedCandle, updateTimeframe, updateCandles } = useApiData();

  useEffect(() => {
    if (parameters) {
      const candleTimeframes = JSON.parse(parameters?.CANDLE_TIME_FRAMES.value);
      setActiveMinute(candleTimeframes[0]);
      setSelectedTimeFrame(candleTimeframes[0]);
      setCandleBuckets(candleTimeframes);
    }
  }, [parameters]);

  const handleCandleButtonClick = async (e, item) => {
    // setAnchorEl(null);
    const candleBucketsFind = candleBuckets.find((c) => c.value === item);
    if (candleBucketsFind) {
      tvWidget
        .activeChart()
        .setResolution(
          String(candleBucketsFind.valueChart) as ResolutionString
        );
      await updateTimeframe(item);
    }
  };

  useEffect(() => {
    const defaultCandleTimeFrame = localStorage
      .getItem("defaultCandleTimeFrame")
      .replace(/\\/g, "")
      .replace(/"/g, "");
    if (defaultCandleTimeFrame && candleBuckets) {
      const activeTimeframe = candleBuckets.find(
        (c) => c.value === defaultCandleTimeFrame
      );
      setActiveMinute(activeTimeframe);
      setSelectedTimeFrame(activeTimeframe);
      updateTimeframe(defaultCandleTimeFrame);
    }
  }, [candleBuckets]);

  useEffect(() => {
    if (selectedSymbol === "BNBUSDT" && selectedCandle === "M1") {
      increaseAmount();
    }
  }, [selectedSymbol]);

  const increaseAmount = () => {
    const minuteIndex = candleBuckets.findIndex(
      (m) => m.value === activeMinute.value
    );
    if (!candleBuckets[minuteIndex + 1]) return;

    const newMinute = candleBuckets[minuteIndex + 1];
    setActiveMinute(newMinute);
    setSelectedTimeFrame(newMinute);
    handleCandleButtonClick("", newMinute.value);
    setOrderHistoryTab(0);
  };

  const decreaseAmount = () => {
    if (selectedSymbol === "BNBUSDT" && activeMinute.value === "M5") return;
    if (activeMinute.value === "M1") return;
    const minuteIndex = candleBuckets.findIndex(
      (m) => m.value === activeMinute.value
    );
    const newMinute = candleBuckets[minuteIndex - 1];
    setActiveMinute(newMinute);
    setSelectedTimeFrame(newMinute);
    handleCandleButtonClick("", newMinute.value);
    setOrderHistoryTab(0);
  };

  if (!candleBuckets) {
    <Skeleton variant="rounded" width={"100%"} height={50} />;
  }

  return (
    <>
      <Stack direction="column" spacing={1}>
        {!isMobile && (
          <Typography
            color={"#5A5A5A"}
            fontWeight={600}
            fontSize={"0.8rem"}
            align={isMobile ? "center" : "left"}
          >
            {t("time")}
          </Typography>
        )}

        <TextField
          sx={{
            fontWeight: 400,
            fontSize: "1rem",
            backgroundColor: "#1F2225",
          }}
          value={activeMinute?.label}
          label={isMobile ? t("time") : undefined}
          disabled={true}
          variant="outlined"
          size={isMobile ? "small" : "medium"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  disabled={activeMinute?.value === "M1"}
                  aria-label="toggle password visibility"
                  onClick={() => {
                    removeAllCallouts();
                    decreaseAmount();
                  }}
                  edge="start"
                >
                  <img
                    src="/static/icons/less-btn.svg"
                    style={{ width: 25, height: 25 }}
                  />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  backgroundColor: "#15181A",
                }}
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    removeAllCallouts();
                    increaseAmount();
                  }}
                  edge="end"
                >
                  <img
                    src="/static/icons/plus-btn.svg"
                    style={{ width: 25, height: 25 }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: isMobile ? "flex-start" : "flex-end",
          }}
        >
          <Typography
            fontWeight={600}
            color={count < 6 ? "#FF5382" : "#00A667"}
            sx={{ fontWeight: "bold" }}
            fontSize={10}
          >
            Faltam
          </Typography>
          <Countdown />
        </Box>
      </Stack>
    </>
  );
}
