import { format, parseISO } from "date-fns";

export const checkActiveAccountActive = (accounts: any) => {
  const accountsRegular = accounts.filter(
    (item: any) => item.environment !== "GLOBAL"
  );
  const accountId = window.localStorage.getItem("accountId");
  const firstAccountRegular = accountsRegular[0];

  if (!localStorage.getItem("ebinexFirstLogin")) {
    const accountDemo = accountsRegular.find(
      (c: any) => c.environment === "TEST"
    );
    localStorage.setItem("ebinexFirstLogin", "true");
    localStorage.setItem("environment", `"${accountDemo.environment}"`);

    return {
      accountId: accountDemo.id,
      activeAccount: accountDemo,
    };
  }

  const accountActive = accounts.find((c: any) => c.id === accountId);

  if (!accountActive) {
    localStorage.setItem("environment", `"${firstAccountRegular.environment}"`);

    return {
      accountId: firstAccountRegular.id,
      activeAccount: firstAccountRegular,
    };
  } else {
    localStorage.setItem("environment", `"${accountActive.environment}"`);

    return {
      accountId,
      activeAccount: accountActive,
    };
  }
};

export const dateFormatMonthAndYear = (date: any) => {
  const parsedDate = parseISO(date);
  return format(parsedDate, 'MMM, yyyy');
};

