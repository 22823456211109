import { forwardRef } from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { Box } from "@mui/material";

const Scrollbar = forwardRef<HTMLDivElement, Scrollbars.Props>((props, ref) => {
  const { children, heigth, ...other } = props;

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  return (
    <Scrollbars
      // @ts-ignore
      ref={ref}
      {...other}
      style={{ height: props.autoHeight ? null : heigth || 250 }}
    >
      {children}
    </Scrollbars>
  );
});

Scrollbar.propTypes = {
  children: PropTypes.node,
  heigth: PropTypes.number,
};

export default Scrollbar;
