import { useLottie } from "lottie-react";
import pusing from "../../../assets/lotties/pusing-green-dot-v1.json";

function PusingDot() {
  const options = {
    animationData: pusing,
    loop: true,
    style: {
      heigth: 20,
      width: 20,
    },
  };

  const { View } = useLottie(options);
  return <>{View}</>;
}

export default PusingDot;
