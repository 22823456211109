import { Close } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import LayoutContext from "src/contexts/LayoutContext";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 550,
      border: "1px rgba(255, 255, 255, 0.2) solid",
    },
    chip: {
      width: 18,
      height: 18,
      backgroundColor: "rgba(145, 158, 171, 0.24)",
      borderRadius: 0.51,
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  })
);

function AlertAccountRealModal() {
  const { t } = useTranslation("dashboard");
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { layout, setModalAlertAccountReal } = useContext(LayoutContext);
  const [checked, setCheked] = useState(false);

  const handleCloseModalReal = () => {
    if (checked) localStorage.setItem("closeModalReal", "true");
    setModalAlertAccountReal(false);
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={layout.modalAlertAccountReal}
      onClose={() => handleCloseModalReal()}
    >
      <Paper
        sx={{ p: 3, minWidth: isMobile ? "80%" : null }}
        className={classes.paper}
      >
        <IconButton
          aria-label="close"
          onClick={() => handleCloseModalReal()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <Stack direction="row" spacing={2}>
          <Stack direction={"column"} spacing={2}>
            <Typography fontSize={16} fontWeight="bold" textAlign={"center"}>
              Você está na Conta Real
            </Typography>
            <Typography fontSize={14} fontWeight={300} textAlign={"center"}>
              A conta real é o ambiente para utilizar seu saldo de forma
              inteligente e multiplicar o seu dinheiro.
            </Typography>
            <Typography fontSize={14} fontWeight={300} textAlign={"center"}>
              Lembre-se de realizar suas operações de forma inteligente,
              utilizando uma boa estratégia e gerenciamento.
            </Typography>
            <FormGroup sx={{ pt: 1 }}>
              <FormControlLabel
                checked={checked}
                onChange={() => setCheked(!checked)}
                control={<Checkbox />}
                sx={{ fontSize: 12, color: "#cfcfcf" }}
                label="Não mostrar essa mensagem novamente"
              />
            </FormGroup>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={(e: any) => handleCloseModalReal()}
              fullWidth
            >
              Operar na Conta Real
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}

export default AlertAccountRealModal;
