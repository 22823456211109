import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles, useTheme } from "@mui/styles";
import type { FC } from "react";

import numeral from "numeral";
import useApiData from "../../hooks/useApiData";
// import useApiData from '../../hooks/useApiData';
import { useTranslation } from "react-i18next";
import useAvailableSymbols from "src/swr/use-available-symbols";
import MenuSelectSymbol from "../menus/MenuSelectSymbol";
import SelectSymbolSkeleton from "../skeleton/SelectSymbolSkeleton";

const icons = {
  Btc: "/static/icons/btc-usdt.png",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.contrastText,
    },
    title: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightLight,
      letterSpacing: 0.5,
      color: theme.palette.text.secondary,
    },
    price: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 0.5,
      color: theme.palette.primary.contrastText,
    },
    greenPrice: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 0.5,
      color: theme.palette.success.main,
    },
    redPrice: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 0.5,
      color: theme.palette.error.main,
    },
  })
);

const SubHeaderDense: FC = () => {
  const { t } = useTranslation("dashboard");
  const classes = useStyles();
  const { getSelectedTickerBook } = useApiData();
  const { loading: loadingSymbols } = useAvailableSymbols();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const ticker = getSelectedTickerBook();

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {loadingSymbols ? <SelectSymbolSkeleton /> : <MenuSelectSymbol />}
      {!isMobile && (
        <Box>
          <Typography
            className={classes.title}
            fontSize={(theme) => theme.typography.pxToRem(10)}
          >
            24h {t("volume")}
          </Typography>
          <Typography
            className={classes.price}
            fontSize={(theme) => theme.typography.pxToRem(12)}
          >
            {`${numeral(ticker?.volume.volume).format("$ 0,0.00")} USDT`}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default SubHeaderDense;
