export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const CANDLE_BUCKETS_DEFAULT = [
  { label: "1 min", value: "M1", valueChart: 1 },
];

export const ENVIRONMENT = [
  {
    label: "REAL",
    value: "REAL",
  },
  {
    label: "DEMO",
    value: "TEST",
  },
];
