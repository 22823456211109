import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError } from "src/utils/toast";
// import CurrencyDollar from '../../icons/CurrencyDollar';
import {
  AccountBalanceWallet,
  CandlestickChart,
  Logout,
  Paid,
  Person,
  SupportAgent
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import LayoutContext from "src/contexts/LayoutContext";
import useUser from "src/swr/use-user";
import useAuth from "../../hooks/useAuth";
import AvatarSkeleton from "../skeleton/AvatarSkeleton";

const AccountMenu: (props) => JSX.Element = (props: {
  openTemporaryModalFunction: () => void;
}) => {
  const { t } = useTranslation("dashboard");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { logout } = useAuth();
  const { user, loading: loadingUser } = useUser();
  const { setActiveTab, setModalSupport } = useContext(LayoutContext);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
      notifyError("Unable to logout");
    }
  };


  return (
    <>
      {/* <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '15px',
        }}
      >
        <User fontSize="large" />
        <AiOutlineCaretDown style={{ marginLeft: '-17px' }} size={10} color="gray" />
        <Typography
          variant="h6"
          color="primary"
          lineHeight="0"
          fontWeight="700"
        >
          {formatCurrency(userBalance)}
        </Typography>
        <MyAiOutlineCaretDown style={{ marginLeft: '-10px' }} size={10} />
      </Box> */}
      <Tooltip title="Account settings">
        <Box sx={{ alignSelf: "center" }}>
          {loadingUser ? (
            <AvatarSkeleton />
          ) : (
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ padding: 0 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar src={user.avatar} sx={{ width: 40, height: 40 }} />
            </IconButton>
          )}
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            border: "1px rgba(255, 255, 255, 0.2) solid",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: -1,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
              borderWidth: "1px 0 0 1px",
              borderColor: "rgba(255, 255, 255, 0.2)",
              borderStyle: "solid",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => navigate("/dashboard/profile?tab=profile")}>
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          {t("my_account")}
        </MenuItem>

        <MenuItem onClick={() => navigate("/dashboard/history/operations")}>
          <ListItemIcon>
            <CandlestickChart fontSize="small" />
          </ListItemIcon>
          {t("operations_history")}
        </MenuItem>
        {/* <MenuItem
          component={RouterLink}
          to="/dashboard/history/balance"
          onClick={() => setActiveTab("balance")}
        >
          <ListItemIcon>
            <Receipt fontSize="small" />
          </ListItemIcon>
          {t("balance_history")}
        </MenuItem> */}
        <Divider />
        <MenuItem onClick={() => navigate("/dashboard/profile?tab=deposit")}>
          <ListItemIcon>
            <AccountBalanceWallet fontSize="small" />
          </ListItemIcon>
          {t("deposit")}
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/dashboard/profile?tab=to_withdraw")}
        >
          <ListItemIcon>
            <Paid fontSize="small" />
          </ListItemIcon>
          {t("to_withdraw")}
        </MenuItem>

        <Divider />
        <MenuItem
          hidden={true}
          onClick={() => navigate("/dashboard/profile?tab=support")}
        >
          <ListItemIcon>
            <SupportAgent fontSize="small" />
          </ListItemIcon>
          {t("support")}
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleLogout} sx={{ color: "#FF5382" }}>
          <ListItemIcon>
            <Logout sx={{ color: "#FF5382" }} fontSize="small" />
          </ListItemIcon>
          {t("log_out")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
